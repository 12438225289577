/**
 * Gallery styles
 */

.media-gallery-container,
.media-set,
.media-share {
    hr {
        border-top: 1px solid #ccc !important;
        width: 100%;
    }
}

.media-gallery-heading {
    font-weight: 400;
    margin: 0 0 0.75rem 0;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 1.2rem;
    color: $gray-dark;
    align-self: flex-start;
}

.media-item-container {
    margin-bottom: 1rem;

    .add-new-item {
        justify-content: center;
        align-items: center;
        align-content: center;
        cursor: pointer;
        padding: 1.5rem;

        i {
            font-size: 4rem;
            color: $gray-dark;
        }

        span {
            font-size: 1.5rem;
            margin-top: 0.5rem;
            font-weight: 600;
        }

        .no-items & {
            min-height: 251px;
        }

        &.is-condensed {
            flex-direction: row;
            justify-content: flex-start;
            padding: 1rem;

            .no-items & {
                min-height: auto;
            }

            i,
            span {
                margin-left: 2.5rem;
            }

            span {
                margin-top: 0;
            }
        }
    }
}

.gallery-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;

    border: 1px solid #fff;
    border-radius: 5px;
    background: #fff;

    padding: 15px 15px 0 15px;

    &.is-condensed {
        padding: 0;

        // .gallery-item-title-wrap {
        //     width: 75%;
        // }
    }

    overflow: hidden;
    height: 100%;

    &.item-type-image .gallery-thumb-container {
        background: url('/images/media/checkerboard.png');
        background-size: 12px 12px;
    }
}

.gallery-item-checkbox {
    position: absolute;
    top: 9px;
    left: 13px;
    z-index: 3;
    cursor: pointer;
    display: none;
}

.gallery-item-checkbox-label {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    padding: 20px;
    line-height: 1;
    background: #fff;
    z-index: 2;
    border-bottom-right-radius: 5px;
    cursor: pointer;
    display: none;
}

.gallery-item:hover .gallery-item-checkbox,
.gallery-item:hover .gallery-item-checkbox-label,
.gallery-item-checkbox:checked,
.gallery-item-checkbox:checked + .gallery-item-checkbox-label {
    display: block;
}

.touch .gallery-item-checkbox {
    top: 18px;
    left: 23px;
    display: block;
}

.touch .gallery-item-checkbox-label {
    display: block;
    padding: 30px;
}

.gallery-link {
    margin: -15px;
    padding: 15px;
    display: block;
    color: #999;

    .icon-file {
        color: #3bafda;
        font-size: 100px;
        text-align: center;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
    }

    .is-condensed & {
        display: flex;
        flex-direction: row;
        width: 100%;
        margin: 0;
        padding: 1rem;

        .gallery-thumb-container {
            width: 25%;
            padding: 0;
            margin: 0 15px 0 0;
            height: 100%;
        }

        .gallery-item-title-wrap {
            flex-grow: 1;

            .gallery-item-title {
                margin: 0 0 5px;
            }
        }
    }
}

.gallery-item:hover {
    background: #fafafa;
    border-color: #eee;
}

.gallery-thumb-container {
    position: relative;
    margin: -15px -15px 0;
    display: block;
    height: auto;
    overflow: hidden;
    padding: 56.24% 0 0 0;

    .side-bar.edit-suite-sidebar & {
        margin: 0;
    }
}

.gallery-icon-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.gallery-item-actions {
    $text-color: map-get($ca-branding, 'text-color');
    $error-color: map-get($ca-branding, 'error');
    $warning-color: map-get($ca-branding, 'warning');
    $success-color: map-get($ca-branding, 'success');

    button {
        color: #333 !important;
        background-color: transparent !important;
        border-color: transparent !important;
        padding: 0 !important;
        flex-shrink: 1;

        i {
            color: lighten($text-color, 30%) !important;
        }

        &:not(:last-child) {
            margin-right: 1rem;
        }

        &:active,
        &:hover,
        &:focus {
            background-color: transparent !important;
            border-color: transparent !important;
            color: black !important;

            i {
                color: black !important;
            }
        }

        &.btn-danger {
            i {
                color: lighten($error-color, 10%) !important;
            }

            &:active,
            &:hover {
                i {
                    color: $error-color !important;
                }
            }
        }

        &.btn-success {
            i {
                color: lighten($success-color, 10%) !important;
            }

            &:active,
            &:hover {
                i {
                    color: $success-color !important;
                }
            }
        }

        &.btn-warning {
            i {
                color: lighten($warning-color, 20%) !important;
            }

            &:active,
            &:hover {
                i {
                    color: $warning-color !important;
                }
            }
        }
    }
}

.video-thumb-container:after {
    font: normal normal normal 14px/1 'Material Design Iconic Font';
    font-size: 80px;
    color: #fff;
    text-shadow: 0px 0px 10px rgba(13, 22, 36, 0.59);
    content: '\f0ba';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0.8;
    transition: all 1s;
}

.gallery-link:hover .video-thumb-container:after {
    opacity: 1;
}

.gallery-thumb {
    width: 100%;
    height: auto;
    display: block;
    max-width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.thumbnail-badges {
    position: absolute;
    display: flex;
    bottom: 10px;
    left: 10px;
}

.thumbnail-badge {
    display: inline-block;
    text-align: center;
    width: 36px;
    height: 36px;
    padding: 5px;
    font-size: 20px;
    line-height: 36px;
    background: #091d2b;
    color: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #2d4b5d;
}

.thumbnail-badge + .thumbnail-badge {
    margin-left: 0.25rem;
}

.thumbnail-timecode {
    position: absolute;
    bottom: 10px;
    right: 10px;
    height: 26px;
    line-height: 26px;
    color: #fff;
    background: rgba(9, 29, 43, 0.75);
    padding: 0 10px;
    font-size: 0.8em;
    border-radius: 4px;
}

.thumbnail-badge > * {
    max-width: 100%;
    height: auto;
}

.thumbnail-badge path {
    fill: #fff;
}

.gallery-item-meta-overlay {
    display: none;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: rgba(255, 255, 255, 0.75);
    padding: 8px;
}

.gallery-item-title {
    margin: 15px 0 10px;

    &.is-success {
        animation-name: bg-flash-success;
        animation-duration: 1000ms;
        animation-iteration-count: 1;
        animation-timing-function: ease-in-out;
    }
}

.gallery-item > *:last-child,
.gallery-link > *:last-child {
    margin-bottom: 0;
}

.gallery-item-meta {
    font-size: 90%;
}

.gallery-item-meta-detail {
    display: inline-block;
}

.side-bar {
    position: relative;

    &.right-bar {
        background: #f2f4f4 !important;
    }

    .nav-tabs > li.active > a,
    .nav-tabs > li.active > a:hover,
    .nav-tabs > li.active > a:focus {
        box-shadow: inset 0 3px 0 0 map-get($ca-branding, 'primary');
        color: map-get($ca-branding, 'primary') !important;
        cursor: default;
        background-color: #fff;
    }

    .nav-tabs > li.active > a,
    .nav-tabs > li.active > a:hover,
    .nav-tabs > li.active > a:focus,
    .nav-tabs > li > a,
    .nav-tabs > li > a:hover,
    .nav-tabs > li > a:focus {
        //border: 1px solid #ddd;
        border-bottom-color: transparent;

        line-height: 4rem;
        font-weight: 500;
        padding-left: 4rem;
        padding-right: 4rem;
    }
}

.item-type-gallery.is-condensed {
    &:after {
        position: absolute;
        font-family: 'Font Awesome 5 Pro';
        font-weight: 900;
        content: '\f660';
        right: 15px;
        margin: auto 0;
        color: $gray-dark;
    }

    &:active:after,
    &:hover:after {
        display: none;
    }
}
