.dataTables_wrapper {
    position: relative;
    min-height: 200px;
}

.dataTables_processing {
    height: 100% !important;
    top: 0 !important;
    background: rgba(white, 0.8) !important;
    z-index: 99;

    span {
        display: flex;
        position: absolute;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        top: 50%;
        right: 50%;
        bottom: 50%;
        left: 50%;
        width: 100%;

        transform: translate(-50%, -50%);

        i {
            font-size: 20px;
            margin: 0.5rem;
        }
    }
}

.dataTable.table tbody tr td:last-child {
    button:not(:last-child) {
        margin-right: 0.25rem;
    }
}
