/* ===========
   Common
 =============*/
body {
    background: #f5f5f5;
    font-family: 'Nunito', sans-serif;
    font-weight: 300;
    margin: 0;
    color: #4c5667;
    overflow-x: hidden !important;
}
html {
    overflow-x: hidden;
    position: relative;
    min-height: 100%;
    background: #f5f5f5;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    color: #505458;
    font-weight: 700;
    margin: 10px 0;
}
h1 {
    line-height: 43px;
}
h2 {
    line-height: 35px;
}
h3 {
    line-height: 30px;
}
h3 small {
    color: #444444;
}
h4 {
    line-height: 22px;
}
h4 small {
    color: #444444;
}
h5 small {
    color: #444444;
}
* {
    outline: none !important;
}
a:hover {
    outline: 0;
    text-decoration: none;
}
a:active {
    outline: 0;
    text-decoration: none;
}
a:focus {
    outline: 0;
    text-decoration: none;
}
