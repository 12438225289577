/**
 * Filepond Overrides
 */

.filepond--root {
    font-size: 1.2em;
}

.filepond {
    &--root {
        font-size: inherit !important;
    }

    &--drop-label {
        cursor: pointer;

        label {
            display: flex !important;
            flex-direction: row;
            align-items: center;
            cursor: pointer !important;
        }

        label:before {
            content: '\f093';
            font-family: 'Font Awesome 5 Pro';
            font-size: 32px;
            display: block;
            margin-right: 0.5rem;
            color: #ccc;
        }
    }
}
