/* ===========
   Portlets
 =============*/
.portlet {
    -moz-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
    -moz-transition: all 0.4s;
    -o-transition: all 0.4s;
    -webkit-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
    -webkit-transition: all 0.4s;
    background: #ffffff;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    transition: all 0.4s;
}
.portlet .portlet-heading {
    border-radius: 3px;
    color: #ffffff;
    padding: 12px 20px;
}
.portlet .portlet-heading .portlet-title {
    color: #ffffff;
    float: left;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 0;
    margin-top: 0;
    text-transform: uppercase;
    letter-spacing: 0.03em;
}
.portlet .portlet-heading .portlet-widgets {
    display: inline-block;
    float: right;
    font-size: 15px;
    line-height: 30px;
    padding-left: 15px;
    position: relative;
    text-align: right;
}
.portlet .portlet-heading .portlet-widgets .divider {
    margin: 0 5px;
}
.portlet .portlet-heading a {
    color: #999999;
}
.portlet .portlet-subheading {
    background: #ffffff;
    padding: 0 15px 15px 15px;
    color: #98a6ad;
}
.portlet .portlet-body {
    -moz-border-radius-bottomleft: 5px;
    -moz-border-radius-bottomright: 5px;
    -webkit-border-bottom-left-radius: 5px;
    -webkit-border-bottom-right-radius: 5px;
    background: #ffffff;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    padding: 15px;
}
.portlet-default .portlet-title {
    color: #797979 !important;
}
.portlet .portlet-heading .portlet-widgets .collapsed .ion-minus-round:before {
    content: '\f217' !important;
}
.portlet .portlet-heading.bg-purple a,
.portlet .portlet-heading.bg-info a,
.portlet .portlet-heading.bg-success a,
.portlet .portlet-heading.bg-primary a,
.portlet .portlet-heading.bg-danger a,
.portlet .portlet-heading.bg-warning a,
.portlet .portlet-heading.bg-inverse a,
.portlet .portlet-heading.bg-pink a {
    color: #ffffff;
}
.panel-disabled {
    background: rgba(243, 242, 241, 0.5);
    cursor: progress;
    bottom: 15px;
    left: 0px;
    position: absolute;
    right: -5px;
    top: 0;
}
.loader-1 {
    -moz-animation: loaderAnimate 1000ms linear infinite;
    -o-animation: loaderAnimate 1000ms linear infinite;
    -webkit-animation: loaderAnimate 1000ms linear infinite;
    animation: loaderAnimate 1000ms linear infinite;
    clip: rect(0, 30px, 30px, 15px);
    height: 30px;
    left: 50%;
    margin-left: -15px;
    margin-top: -15px;
    position: absolute;
    top: 50%;
    width: 30px;
}
.loader-1:after {
    -moz-animation: loaderAnimate2 1000ms ease-in-out infinite;
    -o-animation: loaderAnimate2 1000ms ease-in-out infinite;
    -webkit-animation: loaderAnimate2 1000ms ease-in-out infinite;
    animation: loaderAnimate2 1000ms ease-in-out infinite;
    border-radius: 50%;
    clip: rect(0, 30px, 30px, 15px);
    content: '';
    height: 30px;
    position: absolute;
    width: 30px;
}
