/* =============
     Form elements
  ============= */
.error {
    color: #ef5350;
    font-size: 12px;
    font-weight: 500;
}
.parsley-error {
    border-color: #ef5350 !important;
}
.parsley-errors-list {
    display: none;
    margin: 0;
    padding: 0;
}
.parsley-errors-list.filled {
    display: block;
}
.parsley-errors-list > li {
    font-size: 12px;
    list-style: none;
    color: #f6504d;
}
/* Datepicker */
.datepicker {
    padding: 8px;
}
.datepicker th {
    font-size: 14px !important;
}
.datepicker table tr td.today,
.datepicker table tr td.today:hover,
.datepicker table tr td.today.disabled,
.datepicker table tr td.today.disabled:hover,
.datepicker table tr td.selected,
.datepicker table tr td.selected:hover,
.datepicker table tr td.selected.disabled,
.datepicker table tr td.selected.disabled:hover,
.datepicker table tr td span.active,
.datepicker table tr td span.active:hover,
.datepicker table tr td span.active.disabled,
.datepicker table tr td span.active.disabled:hover {
    background-image: none;
}
.datepicker table tr td span.active:hover,
.datepicker table tr td span.active:hover:hover,
.datepicker table tr td span.active.disabled:hover,
.datepicker table tr td span.active.disabled:hover:hover,
.datepicker table tr td span.active:active,
.datepicker table tr td span.active:hover:active,
.datepicker table tr td span.active.disabled:active,
.datepicker table tr td span.active.disabled:hover:active,
.datepicker table tr td span.active.active,
.datepicker table tr td span.active:hover.active,
.datepicker table tr td span.active.disabled.active,
.datepicker table tr td span.active.disabled:hover.active,
.datepicker table tr td span.active.disabled,
.datepicker table tr td span.active:hover.disabled,
.datepicker table tr td span.active.disabled.disabled,
.datepicker table tr td span.active.disabled:hover.disabled,
.datepicker table tr td span.active[disabled],
.datepicker table tr td span.active:hover[disabled],
.datepicker table tr td span.active.disabled[disabled],
.datepicker table tr td span.active.disabled:hover[disabled] {
    background-color: #3bafda;
}
.datepicker table tr td.active,
.datepicker table tr td.active:hover,
.datepicker table tr td.active.disabled,
.datepicker table tr td.active.disabled:hover {
    background-color: #3bafda !important;
    background-image: none;
    box-shadow: none;
    text-shadow: none;
}
.datepicker thead tr:first-child th:hover,
.datepicker tfoot tr th:hover {
    background-color: #fafafa;
}
.datepicker-inline {
    border: 2px solid #eeeeee;
}
.daterangepicker td.active,
.daterangepicker td.active:hover {
    background-color: #3bafda;
    border-color: #3bafda;
}
.daterangepicker .input-mini.active {
    border: 1px solid #aaaaaa;
}
.daterangepicker .ranges li {
    -webkit-border-radius: 2px;
    border-radius: 2px;
    -moz-border-radius: 2px;
    background-clip: padding-box;
    color: #797979;
    font-weight: 600;
    font-size: 12px;
}
.daterangepicker select.hourselect,
.daterangepicker select.minuteselect,
.daterangepicker select.secondselect,
.daterangepicker select.ampmselect {
    border: 1px solid #e3e3e3;
    padding: 2px;
    width: 60px;
}
.daterangepicker .ranges li.active,
.daterangepicker .ranges li:hover {
    background-color: #3bafda;
    border: 1px solid #3bafda;
}
.search-input {
    margin-bottom: 10px;
}
.ms-selectable {
    box-shadow: none;
    outline: none !important;
}
.ms-container .ms-list.ms-focus {
    box-shadow: none;
}
.ms-container .ms-selectable li.ms-hover {
    background-color: #3bafda;
}
.ms-container .ms-selection li.ms-hover {
    background-color: #3bafda;
}
.note-editor {
    border: 1px solid #eeeeee;
    position: relative;
}
.note-editor .note-toolbar {
    background-color: rgba(152, 166, 173, 0.1);
    border-bottom: 1px solid #eeeeee;
    margin: 0;
}
.note-editor .note-statusbar {
    background-color: #ffffff;
}
.note-editor .note-statusbar .note-resizebar {
    border-top: none;
    height: 15px;
    padding-top: 3px;
}
.note-popover .popover .popover-content {
    padding: 5px 0 10px 5px;
}
.note-toolbar {
    padding: 5px 0 10px 5px;
}
/* Timepicker */
.bootstrap-timepicker-widget table td a:hover {
    background-color: transparent;
    border-color: transparent;
    border-radius: 4px;
    color: #3bafda;
    text-decoration: none;
}
.editor-horizontal .popover-content {
    padding: 9px 30px;
}
.datepicker table tr td.active,
.datepicker table tr td.active:hover,
.datepicker table tr td.active.disabled,
.datepicker table tr td.active.disabled:hover {
    background-color: #3bafda !important;
    background-image: none;
    box-shadow: none;
}
.form-inline:not(.dataTables_wrapper) {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
}
@media (min-width: 576px) {
    .form-inline:not(.dataTables_wrapper) label {
        display: flex;
        align-items: center;
        margin-bottom: 0;
    }

    .form-inline:not(.dataTables_wrapper) .form-control {
        display: inline-block;
        width: auto;
        vertical-align: middle;
    }
}
.form-actions {
    button + button,
    button + input[type='submit'],
    input[type='submit'] + button {
        margin-left: 0.5rem;
    }
}
