.swal2 {
    &-modal {
        width: auto;
        padding: 2.5rem;
        min-width: 32em;
    }

    &-top {
        .swal2-popup {
            top: 15px;
        }
    }

    &-close {
        top: 5px;
        right: 5px;
        font-weight: bold;

        &:hover {
            color: black;
            opacity: 0.5;
        }
    }

    &-content,
    &-actions {
        font-size: 120%;
    }

    &-styled {
        &:focus {
            box-shadow: none;
        }

        &.swal2-cancel {
            color: #333;
            background-color: #dae6ec;
            border-color: #dae6ec;
        }

        &.swal2-confirm,
        &.swal2-cancel {
            i {
                margin-right: 0.5rem;
            }
        }
    }

    &-title {
        margin: 1rem 0;
        line-height: normal;
    }

    &-content {
        font-size: 14px;

        ul,
        p {
            text-align: left;
        }
    }

    // icons
    &-icon {
        margin: 0;

        &.swal2-success {
            border-color: map-get($ca-branding, 'success');
            color: map-get($ca-branding, 'success');
        }

        &.swal2-warning {
            border-color: map-get($ca-branding, 'warning');
            color: map-get($ca-branding, 'warning');
        }

        &.swal2-error {
            border-color: map-get($ca-branding, 'error');
            color: map-get($ca-branding, 'error');
        }

        &.swal2-info {
            border-color: map-get($ca-branding, 'info');
            color: map-get($ca-branding, 'info');
        }

        &.swal2-download {
            border-color: map-get($ca-branding, 'info');
            color: map-get($ca-branding, 'info');
        }

        &.swal2-question {
            border-color: map-get($ca-branding, 'info');
            color: map-get($ca-branding, 'info');
        }

        .swal2-icon-content {
            font-size: 2.75em;
        }
    }
}
