.vue-input-tag-wrapper {
    $info: map-get($ca-branding, 'info');
    $info-light: lighten($info, 45%);
    $info-dark: darken($info, 20%);

    .new-tag {
        margin-bottom: 0 !important;
        margin-top: 0 !important;
    }

    &.form-control {
        height: auto;
        align-content: center;

        .input-tag {
            display: inline-flex;
            flex-direction: row;
            align-items: center;

            border: 1px solid $info;
            border-radius: 2px;

            color: $info-dark;
            background-color: $info-light;
            font-size: 1.2rem;
            font-weight: 400;
            //margin-bottom: 7px;
            margin-right: 5px;
            padding: 2px 5px;

            .remove {
                color: $info-dark;
                cursor: pointer;
                font-weight: 600;
                margin-left: 0.25rem;
            }
        }
    }
}
