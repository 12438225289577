/* ===========
   Tables
 =============*/
.table {
    margin-bottom: 10px;
}
tbody {
    color: #797979;
}
th {
    color: #666666;
    font-size: 15px;
}
.table > tbody > tr > td,
.table > tbody > tr > th,
.table > tfoot > tr > td,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > thead > tr > th {
    border-top: 1px solid #f3f3f3;
}
.table > thead > tr > th {
    border-bottom: 2px solid #f3f3f3;
}
.table > tbody > tr.success > td,
.table > tbody > tr.success > th,
.table > tbody > tr > td.success,
.table > tbody > tr > th.success,
.table > tfoot > tr.success > td,
.table > tfoot > tr.success > th,
.table > tfoot > tr > td.success,
.table > tfoot > tr > th.success,
.table > thead > tr.success > td,
.table > thead > tr.success > th,
.table > thead > tr > td.success,
.table > thead > tr > th.success {
    background-color: rgba(0, 177, 157, 0.15);
}
.table > tbody > tr.info > td,
.table > tbody > tr.info > th,
.table > tbody > tr > td.info,
.table > tbody > tr > th.info,
.table > tfoot > tr.info > td,
.table > tfoot > tr.info > th,
.table > tfoot > tr > td.info,
.table > tfoot > tr > th.info,
.table > thead > tr.info > td,
.table > thead > tr.info > th,
.table > thead > tr > td.info,
.table > thead > tr > th.info {
    background-color: rgba(61, 220, 247, 0.15);
}
.table > tbody > tr.warning > td,
.table > tbody > tr.warning > th,
.table > tbody > tr > td.warning,
.table > tbody > tr > th.warning,
.table > tfoot > tr.warning > td,
.table > tfoot > tr.warning > th,
.table > tfoot > tr > td.warning,
.table > tfoot > tr > th.warning,
.table > thead > tr.warning > td,
.table > thead > tr.warning > th,
.table > thead > tr > td.warning,
.table > thead > tr > th.warning {
    background-color: rgba(255, 170, 0, 0.15);
}
.table > tbody > tr.danger > td,
.table > tbody > tr.danger > th,
.table > tbody > tr > td.danger,
.table > tbody > tr > th.danger,
.table > tfoot > tr.danger > td,
.table > tfoot > tr.danger > th,
.table > tfoot > tr > td.danger,
.table > tfoot > tr > th.danger,
.table > thead > tr.danger > td,
.table > thead > tr.danger > th,
.table > thead > tr > td.danger,
.table > thead > tr > th.danger {
    background-color: rgba(239, 83, 80, 0.15);
}
table.focus-on tbody tr.focused th {
    background-color: #3bafda;
    color: #ffffff;
}
table.focus-on tbody tr.focused td {
    background-color: #3bafda;
    color: #ffffff;
}
.table-rep-plugin {
    /* Table Responsive */
}
.table-rep-plugin tbody th {
    font-size: 14px;
    font-weight: normal;
}
.table-rep-plugin .sticky-table-header.fixed-solution {
    top: 70px !important;
}
.modal-block {
    background: transparent;
    margin: 40px auto;
    max-width: 600px;
    padding: 0;
    position: relative;
    text-align: left;
}
/* Data table */
#datatable-editable .actions a {
    padding: 5px;
}
#datatable-editable .form-control {
    background-color: #ffffff;
    width: 100%;
}
#datatable-editable .fa-trash {
    color: #ef5350;
}
#datatable-editable .fa-times {
    color: #ef5350;
}
#datatable-editable .fa-pencil {
    color: #29b6f6;
}
#datatable-editable .fa-save {
    color: #33b86c;
}
#datatable td {
    font-weight: normal;
}
div.dataTables_paginate ul.pagination {
    margin-top: 30px;
}
div.dataTables_info {
    padding-top: 38px;
}
.dt-buttons {
    float: left;
}
div#datatable-buttons_info {
    float: left;
}
/* Fixed Header */
.fixedHeader-floating {
    top: 70px !important;
}
/* Key table */
table.dataTable th.focus,
table.dataTable td.focus {
    outline: 3px solid #3bafda !important;
    outline-offset: -1px;
}
.table-rep-plugin .table-responsive {
    border: none !important;
}
.table-rep-plugin tbody th {
    font-size: 14px;
    font-weight: normal;
}
.table-rep-plugin .checkbox-row {
    padding-left: 40px;
}
.table-rep-plugin .checkbox-row label {
    display: inline-block;
    padding-left: 5px;
    position: relative;
}
.table-rep-plugin .checkbox-row label::before {
    -o-transition: 0.3s ease-in-out;
    -webkit-transition: 0.3s ease-in-out;
    background-color: #ffffff;
    border-radius: 3px;
    border: 1px solid #cccccc;
    content: '';
    display: inline-block;
    height: 17px;
    left: 0;
    margin-left: -20px;
    position: absolute;
    transition: 0.3s ease-in-out;
    width: 17px;
    outline: none !important;
}
.table-rep-plugin .checkbox-row label::after {
    color: #555555;
    display: inline-block;
    font-size: 11px;
    height: 16px;
    left: 0;
    margin-left: -20px;
    padding-left: 3px;
    padding-top: 1px;
    position: absolute;
    top: -1px;
    width: 16px;
}
.table-rep-plugin .checkbox-row input[type='checkbox'] {
    cursor: pointer;
    opacity: 0;
    z-index: 1;
    outline: none !important;
}
.table-rep-plugin .checkbox-row input[type='checkbox']:disabled + label {
    opacity: 0.65;
}
.table-rep-plugin .checkbox-row input[type='checkbox']:focus + label::before {
    outline-offset: -2px;
    outline: none;
}
.table-rep-plugin .checkbox-row input[type='checkbox']:checked + label::after {
    content: '\f00c';
    font-family: 'Font Awesome 5 Pro';
}
.table-rep-plugin .checkbox-row input[type='checkbox']:disabled + label::before {
    background-color: #eeeeee;
    cursor: not-allowed;
}
.table-rep-plugin .checkbox-row input[type='checkbox']:checked + label::before {
    background-color: #3bafda;
    border-color: #3bafda;
}
.table-rep-plugin .checkbox-row input[type='checkbox']:checked + label::after {
    color: #ffffff;
}
.table-ellipsis {
    table-layout: fixed;

    td {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}
/* Tablesaw */
.tablesaw thead {
    background: #f5f5f5;
    background-image: none;
    border: none;
}
.tablesaw thead th {
    text-shadow: none;
    letter-spacing: 0.06em;
}
.tablesaw thead tr:first-child th {
    padding-top: 1.1em;
    padding-bottom: 0.9em;
}
.tablesaw thead tr:first-child th {
    font-weight: 600;
    font-family: inherit;
    border: none;
}
.tablesaw td,
.tablesaw tbody th {
    font-size: inherit;
    line-height: inherit;
    padding: 10px !important;
}
.tablesaw-stack tbody tr,
.tablesaw tbody tr {
    border-bottom: none;
}
.tablesaw-sortable .tablesaw-sortable-head.tablesaw-sortable-ascending button:after,
.tablesaw-sortable .tablesaw-sortable-head.tablesaw-sortable-descending button:after {
    font-family: 'Font Awesome 5 Pro';
    font-size: 10px;
}
.tablesaw-sortable .tablesaw-sortable-head.tablesaw-sortable-ascending button:after {
    content: '\f176';
}
.tablesaw-sortable .tablesaw-sortable-head.tablesaw-sortable-descending button:after {
    content: '\f175';
}
.tablesaw-bar .btn-select.btn-small:after,
.tablesaw-bar .btn-select.btn-micro:after {
    font-size: 8px;
    padding-right: 10px;
}
.tablesaw-swipe .tablesaw-cell-persist {
    box-shadow: none;
}
.tablesaw-enhanced .tablesaw-bar .btn {
    text-shadow: none;
    background-image: none;
}
.tablesaw-enhanced .tablesaw-bar .btn.btn-select:hover {
    background: #ffffff;
}
.tablesaw-enhanced .tablesaw-bar .btn:hover,
.tablesaw-enhanced .tablesaw-bar .btn:focus,
.tablesaw-enhanced .tablesaw-bar .btn:active {
    color: #3bafda !important;
    background-color: #f5f5f5;
    outline: none !important;
    box-shadow: none !important;
    background-image: none;
}

.review-info-table {
    width: 100%;
}

.review-info-table th {
    white-space: nowrap;
}

.review-info-table th,
.review-info-table td {
    font-size: 80%;
}

.review-info-table td {
    white-space: normal !important;
}
