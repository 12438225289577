.panel-collapse {
    position: relative;
}

.map-top-left-controls {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 1;

    a {
        background: #fff;
        color: #091d2b;
    }

    .active {
        background: #3bafda;
        color: #fff;

        .fa-square:before {
            content: '\f14a';
        }
    }

    .fa {
        margin-right: 5px;
    }

    a.disabled .fa {
        @extend .fa-spinner !optional;
        @extend .fa-spin !optional;
    }
}

.mapboxgl-popup-content {
    padding-top: 0;
    padding-bottom: 5px;
    font-family: 'Barlow', sans-serif;
}

.mapbox-popup-table {
    margin-bottom: 0;

    tr {
        display: flex;
        border-top: 1px solid #f3f3f3;
        align-items: center;

        &:first-child {
            border-top: 0 none;
        }
    }
    td {
        border-top: 0 none !important;
    }
}

.mapbox-popup-table-icon {
    font-size: 22px;
    opacity: 0.3;
}
.mapbox-popup-table-value {
    font-size: 36px;
    color: #091d2b;
    word-break: keep-all;
}
.mapbox-popup-table-sentiment {
    padding: 1px 5px;
    color: #fff;
    border-radius: 4px;
}
.mapbox-popup-table-meta {
    font-size: 12px;
    color: #9a9ea0;
}

.marker {
    position: absolute;
    bottom: 0%;
    left: 0%;
    transform: translateX(-50%);

    border-radius: 50%;
    border: 8px solid #2881e8;
    width: 24px;
    height: 24px;
    cursor: pointer;
}

.marker::after {
    position: absolute;
    content: '';
    width: 0px;
    height: 0px;
    bottom: -30px;
    left: -6px;
    border: 10px solid transparent;
    border-top: 17px solid #2881e8;
}

.portlet-title {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

/**
 * From: views/analyse/interactives/all.blade.php
 * Also: views/analyse/interactives/realtime.blade.php
 */

body.dashboard-page {
    &.exporting {
        #mapboxMap {
            display: none !important;
        }

        #pdf-page-1,
        #pdf-page-2 {
            background-color: #fff;
        }

        #report-tables .panel-padded {
            height: auto !important;
        }

        #export-header {
            display: block !important;
        }

        #mapImage {
            display: block !important;
        }
    }

    #export-header {
        margin-bottom: 15px;
        display: none;
    }

    #mapImage {
        display: none;
    }

    .export-client,
    .export-project {
        padding-left: 10px;
    }

    .export-client {
        font-size: 18px;
        font-weight: bold;
    }

    .export-project {
        font-size: 16px;
        font-weight: bold;
        color: #337ab7;
    }

    .export-meta {
        font-size: 16px;
        margin-top: 5px;
    }

    .pointer-events-disabled {
        opacity: 0.5;
        pointer-events: none;
    }

    div.content > div.container {
        max-width: 1750px;
    }

    #sentiment-charts {
        .col-md-3 {
            margin-bottom: 20px;
        }

        canvas {
            // cause issues with flot, tooltip placement
            // max-width: 70%;
            // height: 230px !important;
        }
    }

    #counter-live-visitors {
        p {
            display: inline-block;
        }

        &:hover {
            cursor: pointer;
            opacity: 0.7;
        }
    }

    a.view-all-charts {
        clear: both;
        display: block;
        width: 190px;
        margin: 0 auto 20px auto;
    }

    //@page { size: landscape; }

    @media print {
        #topnav,
        a.view-all,
        .hide-print {
            display: none !important;
        }

        .page-break {
            display: block;
            page-break-before: always;
        }

        #export-header {
            display: block !important;
        }

        #report-tables .panel-padded {
            height: auto !important;
        }

        canvas {
            max-width: 100%;
            max-height: 100%;
            display: block;
        }

        #sentiment-charts {
            .col-md-3 {
                width: 45% !important;
                max-height: 300px;
                margin-bottom: 30px;
            }
            canvas {
                max-width: 70%;
                height: 230px !important;
            }
        }

        .dataTables_paginate,
        .dataTables_length {
            display: none;
        }
    }

    #flotTip {
        padding: 10px;
        background-color: #fff;
        z-index: 100;
        color: #9f9f9f;
        border: 1px solid #d5d9db;
    }

    .pieLabel div {
        color: white !important;
        text-shadow: 0 0 4px #000;
    }
}

.stakeholder-window .pagination {
    display: flex;
    justify-content: space-between;
}

.sentiment-chart {
    page-break-after: always;
    page-break-inside: avoid;
    -webkit-region-break-inside: avoid;
}

#reportrange {
    position: relative;
    width: 300px;
    padding: 5px 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    background: #fff;

    cursor: pointer;

    .down-arrow {
        position: absolute;
        right: 10px;
        top: 7px;
    }
}
