/* ===========
   Bootstrap-custom
 =============*/
.row {
    margin-right: -10px;
    margin-left: -10px;
}
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-xs-1,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9 {
    padding-left: 10px;
    padding-right: 10px;
}
.breadcrumb {
    background-color: transparent;
    margin-bottom: 15px;
    margin-top: 5px;
}
.dropdown-menu {
    padding: 4px 0;
    -webkit-animation: dropdownOpen 0.3s ease-out;
    -o-animation: dropdownOpen 0.3s ease-out;
    animation: dropdownOpen 0.3s ease-out;
    border: 0;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
}
.dropdown-menu > li > a {
    padding: 6px 20px;
    cursor: pointer;
}
code {
    color: #5d9cec;
    border-radius: 4px;
}
code,
pre {
    background-color: #f4f8fb;
}
.bg-empty {
    background: transparent !important;
}
.bg-primary {
    background-color: #346ae3 !important;
}
.bg-success {
    background-color: #00b19d !important;
}
.bg-info {
    background-color: #6cc6d6 !important;
}
.bg-warning {
    background-color: #ffaa00 !important;
}
.bg-danger {
    background-color: #ef5350 !important;
}
.bg-muted {
    background-color: #f5f5f5 !important;
}
.bg-inverse {
    background-color: #4c5667 !important;
}
.bg-purple {
    background-color: #7266ba !important;
}
.bg-pink {
    background-color: #f76397 !important;
}
.bg-white {
    background-color: #ffffff !important;
}
.text-white {
    color: #ffffff;
}
.text-danger {
    color: #ef5350;
}
.text-muted {
    color: #98a6ad;
}
.text-primary {
    color: #3bafda;
}
.text-warning {
    color: #ffaa00;
}
.text-success {
    color: #00b19d;
}
.text-info {
    color: #6cc6d6;
}
.text-inverse {
    color: #4c5667;
}
.text-pink {
    color: #f76397;
}
.text-purple {
    color: #7266ba;
}
.text-dark {
    color: #797979 !important;
}
.form-control {
    -moz-border-radius: 2px;
    -moz-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
    -webkit-border-radius: 2px;
    -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
    background-color: #fafafa;
    border-radius: 2px;
    border: 1px solid #eeeeee;
    box-shadow: none;
    color: rgba(0, 0, 0, 0.6);
    font-size: 14px;
}
.form-control:focus {
    background: #ffffff;
    border: 1px solid #e0e0e0;
    box-shadow: none;
}
.label {
    color: #ffffff !important;
}
.label-primary {
    background-color: #3bafda;
}
.label-success {
    background-color: #00b19d;
}
.label-info {
    background-color: #6cc6d6;
}
.label-warning {
    background-color: #ffaa00;
}
.label-danger {
    background-color: #ef5350;
}
.label-purple {
    background-color: #7266ba;
}
.label-pink {
    background-color: #f76397;
}
.label-dark {
    background: #4f595b;
}
.label-inverse {
    background-color: #4c5667;
}
.badge {
    font-weight: 600;
    //padding: 3px 5px;
    font-size: 12px;
    margin-top: 1px;
}
.badge-xs {
    font-size: 9px;
}
.badge-xs,
.badge-sm {
    -webkit-transform: translate(0, -2px);
    -ms-transform: translate(0, -2px);
    -o-transform: translate(0, -2px);
    transform: translate(0, -2px);
}
.badge-primary {
    background-color: #3bafda;
}
.badge-success {
    background-color: #00b19d;
}
.badge-info {
    background-color: #6cc6d6;
}
.badge-warning {
    background-color: #ffaa00;
}
.badge-danger {
    background-color: #ef5350;
}
.badge-purple {
    background-color: #7266ba;
}
.badge-pink {
    background-color: #f76397;
}
.badge-inverse {
    background-color: #4c5667;
}
/* Pagination/ Pager */
.pagination > li:first-child > a,
.pagination > li:first-child > span {
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px;
}
.pagination > li:last-child > a,
.pagination > li:last-child > span {
    border-bottom-right-radius: 3px;
    border-top-right-radius: 3px;
}
.pagination > li > a,
.pagination > li > span {
    color: #636e7b;
}
.pagination > li > a:hover,
.pagination > li > span:hover,
.pagination > li > a:focus,
.pagination > li > span:focus {
    background-color: #e4e7ea;
}
.pagination-split li {
    margin-left: 5px;
    display: inline-block;
    float: left;
}
.pagination-split li:first-child {
    margin-left: 0;
}
.pagination-split li a {
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
}
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
    background-color: #3bafda;
    border-color: #3bafda;
}
.pager .disabled > a,
.pager .disabled > a:focus,
.pager .disabled > a:hover,
.pager .disabled > span {
    opacity: 0.6;
}
.pager li > a,
.pager li > span {
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    color: #4c5667;
}
.tabs {
    background-color: #ffffff;
    margin: 0 auto;
    padding: 0px;
    position: relative;
    white-space: nowrap;
    width: 100%;
}
.tabs li.tab {
    background-color: #ffffff;
    display: block;
    float: left;
    margin: 0;
    text-align: center;
}
.tabs li.tab a {
    -moz-transition: color 0.28s ease;
    -ms-transition: color 0.28s ease;
    -o-transition: color 0.28s ease;
    -webkit-transition: color 0.28s ease;
    color: #ee6e73;
    display: block;
    height: 100%;
    text-decoration: none;
    transition: color 0.28s ease;
    width: 100%;
}
.tabs li.tab a.active {
    color: #3bafda !important;
}
.tabs .indicator {
    background-color: #3bafda;
    bottom: 0;
    height: 2px;
    position: absolute;
    will-change: left, right;
}
.tabs-top .indicator {
    top: 0;
}
.nav.nav-tabs + .tab-content {
    background: #ffffff;
    margin-bottom: 20px;
    padding: 20px;
}
.card-box .nav-pills li a {
    color: #4c5667 !important;
    line-height: 36px !important;
    border-radius: 30px;
    padding: 0px 20px;
}
.card-box .nav-pills li.active a {
    color: #ffffff !important;
}
.card-box .nav-pills li.active a {
    background-color: #3bafda !important;
}
.tabs-vertical-env {
    margin-bottom: 30px;
}
.tabs-vertical-env .tab-content {
    background: #ffffff;
    display: table-cell;
    margin-bottom: 30px;
    padding: 30px;
    vertical-align: top;
}
.tabs-vertical-env .nav.tabs-vertical {
    display: table-cell;
    min-width: 120px;
    vertical-align: top;
    width: 150px;
}
.tabs-vertical-env .nav.tabs-vertical li.active > a {
    background-color: #ffffff;
    border: 0;
}
.tabs-vertical-env .nav.tabs-vertical li > a {
    color: #333333;
    text-align: center;
    font-weight: 500;
    white-space: nowrap;
}
.nav.nav-tabs > li.active > a {
    background-color: #ffffff;
    border: 0;
}
.nav.nav-tabs > li > a {
    background-color: transparent;
    border-radius: 0;
    border: none;
    color: #333333 !important;
    cursor: pointer;
    line-height: 50px;
    font-weight: 500;
    padding-left: 20px;
    padding-right: 20px;
}
.nav.nav-tabs > li > a:hover {
    color: #3bafda !important;
}
.nav.tabs-vertical > li > a {
    background-color: transparent;
    border-radius: 0;
    border: none;
    color: #333333 !important;
    cursor: pointer;
    line-height: 50px;
    padding-left: 20px;
    padding-right: 20px;
}
.nav.tabs-vertical > li > a:hover {
    color: #3bafda !important;
}
.tab-content {
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
    color: #777777;
}
.nav.nav-tabs > li:last-of-type a {
    margin-right: 0px;
}
.nav.nav-tabs {
    border-bottom: 0;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
}
.navtab-custom li {
    margin-bottom: -2px;
}
.navtab-custom li a {
    border-top: 2px solid transparent !important;
}
.navtab-custom li.active a {
    border-top: 2px solid #3bafda !important;
}
.nav-tab-left.navtab-custom li a {
    border: none !important;
    border-left: 2px solid transparent !important;
}
.nav-tab-left.navtab-custom li.active a {
    border-left: 2px solid #3bafda !important;
}
.nav-tab-right.navtab-custom li a {
    border: none !important;
    border-right: 2px solid transparent !important;
}
.nav-tab-right.navtab-custom li.active a {
    border-right: 2px solid #3bafda !important;
}
.nav-tabs.nav-justified > .active > a,
.nav-tabs.nav-justified > .active > a:hover,
.nav-tabs.nav-justified > .active > a:focus,
.tabs-vertical-env .nav.tabs-vertical li.active > a {
    border: none;
}
.nav-tabs > li.active > a,
.nav-tabs > li.active > a:focus,
.nav-tabs > li.active > a:hover,
.tabs-vertical > li.active > a,
.tabs-vertical > li.active > a:focus,
.tabs-vertical > li.active > a:hover {
    color: #3bafda !important;
}
/* Dropcap */
.dropcap {
    font-size: 3.1em;
}
.dropcap,
.dropcap-circle,
.dropcap-square {
    display: block;
    float: left;
    font-weight: 400;
    line-height: 36px;
    margin-right: 6px;
    text-shadow: none;
}
/* Modal */
.modal .modal-dialog .modal-content {
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    border-color: #dddddd;
    border-radius: 2px;
    box-shadow: none;
    padding: 30px;
}
.modal .modal-dialog .modal-content .modal-header {
    border-bottom-width: 2px;
    margin: 0;
    padding: 0;
    padding-bottom: 15px;
}
.modal .modal-dialog .modal-content .modal-body {
    padding: 20px 0;
}
.modal .modal-dialog .modal-content .modal-footer {
    padding: 0;
    padding-top: 15px;
}
.modal-full {
    width: 98%;
}
.modal-content .nav.nav-tabs + .tab-content {
    margin-bottom: 0px;
}
.modal-content .panel-group {
    margin-bottom: 0px;
}
.modal-content .panel {
    border-top: none;
}
/* Custom-modal */
.modal-demo {
    background-color: #fff;
    width: 600px;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    -moz-border-radius: 4px;
    background-clip: padding-box;
    display: none;
}
.modal-demo .close {
    position: absolute;
    top: 15px;
    right: 25px;
    color: #eeeeee;
}
.custom-modal-title {
    padding: 15px 25px 15px 25px;
    line-height: 22px;
    font-size: 18px;
    background-color: #3bafda;
    color: #ffffff;
    text-align: left;
    margin: 0px;
}
.custom-modal-text {
    padding: 20px;
}
.custombox-modal-flash .close,
.custombox-modal-rotatedown .close {
    top: 20px;
    z-index: 9999;
}
.tabs-vertical-env .tab-content {
    margin-bottom: 0px;
}
.table > thead > tr > td.middle-align,
.table > tbody > tr > td.middle-align {
    vertical-align: middle;
}
.legendLabel {
    padding-left: 10px !important;
}
/* List Group */
.list-group-item.active {
    background: rgba(152, 166, 173, 0.1);
    border-color: rgba(152, 166, 173, 0.3);
    color: #98a6ad;
    z-index: 2;
}
.list-group-item.active:hover {
    background: rgba(152, 166, 173, 0.1);
    border-color: rgba(152, 166, 173, 0.3);
    color: #98a6ad;
    z-index: 2;
}
.list-group-item.active:hover .list-group-item-text {
    color: #98a6ad;
}
.list-group-item.active:focus {
    background: rgba(152, 166, 173, 0.1);
    border-color: rgba(152, 166, 173, 0.3);
    color: #98a6ad;
    z-index: 2;
}
.list-group-item.active:focus .list-group-item-text {
    color: #98a6ad;
}
.list-group-item.active .list-group-item-text {
    color: #98a6ad;
}
.list-group-item.disabled,
.list-group-item.disabled:focus,
.list-group-item.disabled:hover {
    background: rgba(152, 166, 173, 0.2);
    border-color: rgba(152, 166, 173, 0.3);
    color: #98a6ad;
}
.list-group-item {
    border: 1px solid rgba(152, 166, 173, 0.25);
    border-radius: 0px;
    padding: 12px 20px;
}
.list-group-item:first-child {
    border-radius: 0px;
    padding: 12px 20px;
}
.list-group-item:last-child {
    border-radius: 0px;
    padding: 12px 20px;
}
.list-group-item:hover {
    background: rgba(152, 166, 173, 0.1);
}
.list-group-item-heading {
    font-weight: 300;
}
.list-group-item.active > .badge {
    color: #3bafda;
}
.nav-pills > .active > a > .badge {
    color: #3bafda;
}
.has-success .form-control {
    border-color: $brand-success !important;
    box-shadow: none !important;
}
.has-warning .form-control {
    border-color: $brand-warning !important;
    box-shadow: none !important;
}
.has-error .form-control {
    border-color: $brand-danger !important;
    box-shadow: none !important;
    background: $input-bg-error !important;
}
.input-group-addon {
    border-radius: 2px;
    border: 1px solid #eeeeee;
}
/* Tooltips */
.tooltip-inner {
    border-radius: 1px;
    padding: 6px 10px;
}
.jqstooltip {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    width: auto !important;
    height: auto !important;
}
/* Popover */
.popover {
    font-family: inherit;
    border: none;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    -moz-border-radius: 3px;
    background-clip: padding-box;
}
.popover .popover-title {
    background-color: transparent;
    color: #3bafda;
    font-weight: 600;
}

.font-light {
    font-weight: 300;
}
.font-normal {
    font-weight: normal;
}
.font-13 {
    font-size: 13px;
}
.wrapper-md {
    padding: 20px;
}
.pull-in {
    margin-left: -20px;
    margin-right: -20px;
}

.center-page {
    float: none !important;
    margin: 0 auto;
}
.bx-s-0 {
    box-shadow: none !important;
}
.bx-shadow {
    -moz-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
}
.mx-box {
    max-height: 380px;
    min-height: 380px;
}
.thumb-sm {
    height: 32px;
    width: 32px;
}
.thumb-md {
    height: 48px;
    width: 48px;
}
.thumb-lg {
    height: 88px;
    width: 88px;
}
