/*
Template Name: Minton Dashboard
Author: CoderThemes
Email: coderthemes@gmail.com
File: Responsive
*/

@media only screen and (max-width: 6000px) and (min-width: 700px) {
    .wrapper.right-bar-enabled .right-bar {
        right: 0;
        z-index: 99;
    }
}
@media (max-width: 1039px) {
    .button-menu-mobile {
        display: block !important;
    }
    .button-menu-mobile span {
        font-size: 11px;
        text-transform: uppercase;
        vertical-align: middle;
    }
    .enlarged .left.side-menu {
        margin-left: -75px;
    }
    .content-page {
        margin-left: 0px !important;
    }
    .footer {
        left: 0px !important;
    }
    .mobile-sidebar {
        left: 0px;
    }
}
@media (min-width: 768px) and (max-width: 1024px) {
    .email-msg {
        display: none;
    }
}
@media (min-width: 768px) and (max-width: 991px) {
    body {
        overflow-x: hidden;
    }
}
@media (max-width: 767px) {
    body {
        overflow-x: hidden;
    }
    .mobile-content {
        left: 250px;
        right: -250px;
    }
    .navbar-nav .open .dropdown-menu {
        background-color: #ffffff;
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
        left: auto;
        position: absolute;
        right: 0;
    }
    .todo-send {
        margin-top: 10px;
        padding-left: 15px;
    }
    .chat-inputbar {
        padding-left: 15px;
    }
    .chat-send {
        margin-top: 10px;
        padding-left: 15px;
        padding-right: 15px;
    }
    .dataTables_wrapper .col-xs-6 {
        width: 100%;
        text-align: left;
    }
}
@media (max-width: 480px) {
    .side-menu {
        z-index: 10 !important;
    }
    .button-menu-mobile {
        display: block;
    }
    .search-bar {
        display: none !important;
    }
    .user-detail {
        bottom: 0;
        background-color: #2a3142;
        z-index: 1;
    }
    #sidebar-menu {
        overflow: auto;
        height: 100%;
        padding-top: 0;
        padding-bottom: 70px;
    }
}
@media (max-width: 420px) {
    .hide-phone {
        display: none !important;
    }
}
/* Container-alt */
@media (min-width: 768px) {
    .container-alt {
        width: 750px;
    }
}
@media (min-width: 992px) {
    .container-alt {
        width: 970px;
    }
}
@media (min-width: 1200px) {
    .container-alt {
        width: 1170px;
    }
}
@media (max-width: 419px) {
    .topbar-left {
        width: 70px !important;
    }
    .logo .icon-c-logo {
        display: inline-block !important;
        line-height: 58px !important;
    }
    .logo span {
        display: none !important;
    }
    .content-page {
        margin-left: 70px;
    }
    .forced .side-menu.left {
        box-shadow: 0 12px 12px rgba(0, 0, 0, 0.1);
    }
    .enlarged .side-menu.left {
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1) !important;
    }
}

@media (max-width: 768px) {
    // vertical column headers for project summary table
    #projectSummaryTable {
        th {
            text-align: center;
            vertical-align: bottom;
        }

        th span {
            -ms-writing-mode: tb-rl;
            -webkit-writing-mode: vertical-rl;
            transform: rotate(-180deg);
            writing-mode: vertical-rl;
            white-space: nowrap;
        }
    }
}
