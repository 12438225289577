// global variables, bootstrap overrides
@import 'variables';

// npm vendor files

// bootstrap
$icon-font-path: '~bootstrap-sass/assets/fonts/bootstrap/';
@import '~bootstrap-sass/assets/stylesheets/bootstrap';

// fontawesome
@import '~@fortawesome/fontawesome-pro/css/all.css';
@import '~@fortawesome/fontawesome-pro/css/brands.css';

// other vendor
@import '~sweetalert2/src/sweetalert2.scss';
@import '~vue-select/src/scss/vue-select.scss';
@import '~mapbox-gl/dist/mapbox-gl.css';
@import '~vue2-animate/src/sass/vue2-animate.scss';
@import '~select2/dist/css/select2.min.css';
@import '~select2-bootstrap-theme/dist/select2-bootstrap.min.css';
@import '~filepond/dist/filepond.min.css';
@import '~filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
//@import '~filepond-plugin-media-preview/dist/filepond-plugin-media-preview.css';

// custom vendor files
@import 'vendor/filepond';
@import 'vendor/mapbox';
@import 'vendor/sweetalert';
@import 'vendor/toasted';
@import 'vendor/input-tag';
@import 'vendor/vuelidate';
@import 'vendor/vue-tippy';
@import 'vendor/select2';
@import 'vendor/fontawesome-iconpicker';
@import 'vendor/vue-multiselect';
@import 'vendor/vue-query-builder';
@import 'vendor/vue-treeselect';
@import 'vendor/datatables';

// components
@import 'components/all';

// utilities
@import 'utilities/all';

// Other CSS files TODO: refactor to components, utils etc...
@import 'css/core';
@import 'css/typography';
@import 'css/bootstrap-custom';
@import 'css/pages';
@import 'css/ca';
@import 'css/dashboards';
@import 'css/responsive';
@import 'css/maps-hotspots';
@import 'css/animations';
@import 'css/media';

// TODO: put below somewhere should only be imports here.
@media print {
    body {
        -webkit-print-color-adjust: exact;
    }
    @for $i from 1 through 12 {
        .col-print-#{$i} {
            width: #{percentage(round($i * 8.33) / 100)};
            float: left;
        }
    }
}

.scrollable-menu {
    height: auto;
    max-height: 200px;
    overflow-x: hidden;
}

.revision-label {
    font-size: 65%;
    float: right;
    margin-top: 3px;
}

#datatable td {
    font-weight: 400;
    min-width: 100px; /* add this line */
}

.dashboard-cards a {
    color: #fff;
    &:hover .wid-title {
        opacity: 0.7;
    }
}

/**
 * Temporary SCSS - will need migration to R4.0.0
 */
.media-title-edit {
    cursor: pointer;

    i.fa-pencil {
        color: #3bafdb;
    }
}

.gallery-item-title {
    .media-title-edit {
        display: inline-block;
        visibility: hidden;
    }

    &:hover {
        .media-title-edit {
            visibility: visible;
        }
    }
}

.btn.gallery-item-meta-detail {
    color: #ffffff;

    .fa {
        color: #ffffff;
        padding-right: 5px;
    }

    &:hover {
        cursor: default;
    }
}

.media-review-incomplete {
    .btn.gallery-item-meta-detail {
        background-color: darkorange;
    }
}

.media-review-underway {
    .btn.gallery-item-meta-detail {
        background-color: #28a5d4;
    }
}

.media-review-completed {
    .btn.gallery-item-meta-detail {
        background-color: #87c447;
    }
}

[v-cloak] {
    display: none;
}
