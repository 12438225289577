/* ===========
   Core file List

   - Timeline
   - Timeline-2
   - Sweet Alerts
   - Notification
   - Nestable list
   - Maps
   - Email
   - Form Advanced
   - Charts
   - Maintenance
   - Countdown
   - Gallery
   - Tree view
   - Pricing
   - FAQ

 =============*/
/* ===========
  Timeline
 =============*/
.timeline {
    border-collapse: collapse;
    border-spacing: 0;
    display: table;
    margin-bottom: 50px;
    position: relative;
    table-layout: fixed;
    width: 100%;
}
.timeline .time-show {
    margin-bottom: 30px;
    margin-right: -75px;
    margin-top: 30px;
    position: relative;
}
.timeline .time-show a {
    color: #ffffff;
}
.timeline:before {
    background-color: #d8d9df;
    bottom: 0px;
    content: '';
    left: 50%;
    position: absolute;
    top: 30px;
    width: 1px;
    z-index: 0;
}
.timeline .timeline-icon {
    -webkit-border-radius: 50%;
    background: #d8d9df;
    border-radius: 50%;
    border: 1px solid #d8d9df;
    color: #ffffff;
    display: block;
    height: 20px;
    left: -54px;
    margin-top: -10px;
    position: absolute;
    text-align: center;
    top: 50%;
    width: 20px;
}
.timeline .timeline-icon i {
    margin-top: 9px;
}
.timeline .time-icon:before {
    font-size: 16px;
    margin-top: 5px;
}
h3.timeline-title {
    color: #c8ccd7;
    font-size: 20px;
    font-weight: 400;
    margin: 0 0 5px;
    text-transform: uppercase;
}
.timeline-item {
    display: table-row;
}
.timeline-item:before {
    content: '';
    display: block;
    width: 50%;
}
.timeline-item .timeline-desk .arrow {
    border-bottom: 8px solid transparent;
    border-right: 8px solid #ffffff !important;
    border-top: 8px solid transparent;
    display: block;
    height: 0;
    left: -7px;
    margin-top: -10px;
    position: absolute;
    top: 50%;
    width: 0;
}
.timeline-item.alt:after {
    content: '';
    display: block;
    width: 50%;
}
.timeline-item.alt .timeline-desk .arrow-alt {
    border-bottom: 8px solid transparent;
    border-left: 8px solid #ffffff !important;
    border-top: 8px solid transparent;
    display: block;
    height: 0;
    left: auto;
    margin-top: -10px;
    position: absolute;
    right: -7px;
    top: 50%;
    width: 0;
}
.timeline-item.alt .timeline-desk .album {
    float: right;
    margin-top: 20px;
}
.timeline-item.alt .timeline-desk .album a {
    float: right;
    margin-left: 5px;
}
.timeline-item.alt .timeline-icon {
    left: auto;
    right: -56px;
}
.timeline-item.alt:before {
    display: none;
}
.timeline-item.alt .panel {
    margin-left: 0;
    margin-right: 45px;
}
.timeline-item.alt .panel .panel-body p + p {
    margin-top: 10px !important;
}
.timeline-item.alt h4 {
    text-align: right;
}
.timeline-item.alt p {
    text-align: right;
}
.timeline-item.alt .timeline-date {
    text-align: right;
}
.timeline-desk {
    display: table-cell;
    vertical-align: top;
    width: 50%;
}
.timeline-desk h4 {
    font-size: 16px;
    font-weight: 300;
    margin: 0;
}
.timeline-desk .panel {
    background: #ffffff;
    display: block;
    margin-bottom: 5px;
    margin-left: 45px;
    position: relative;
    text-align: left;
}
.timeline-desk h5 span {
    color: #797979;
    display: block;
    font-size: 12px;
    margin-bottom: 4px;
}
.timeline-desk p {
    color: #999999;
    font-size: 14px;
    margin-bottom: 0;
}
.timeline-desk .album {
    margin-top: 12px;
}
.timeline-desk .album a {
    float: left;
    margin-right: 5px;
}
.timeline-desk .album img {
    height: 36px;
    width: auto;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    -moz-border-radius: 3px;
    background-clip: padding-box;
}
.timeline-desk .notification {
    background: none repeat scroll 0 0 #ffffff;
    margin-top: 20px;
    padding: 8px;
}
/* ===========
   Timeline-2
 =============*/
.timeline-2 {
    border-left: 2px solid #3bafda;
    position: relative;
}
.timeline-2 .time-item:after {
    background-color: #ffffff;
    border-color: #3bafda;
    border-radius: 10px;
    border-style: solid;
    border-width: 2px;
    bottom: 0;
    content: '';
    height: 10px;
    left: 0;
    margin-left: -6px;
    position: absolute;
    top: 5px;
    width: 10px;
}
.time-item {
    border-color: #dee5e7;
    padding-bottom: 10px;
    position: relative;
}
.time-item:before {
    content: ' ';
    display: table;
}
.time-item:after {
    background-color: #ffffff;
    border-color: #3bafda;
    border-radius: 10px;
    border-style: solid;
    border-width: 2px;
    bottom: 0;
    content: '';
    height: 14px;
    left: 0;
    margin-left: -8px;
    position: absolute;
    top: 5px;
    width: 14px;
}
.time-item-item:after {
    content: ' ';
    display: table;
}
.item-info {
    margin-bottom: 15px;
    margin-left: 15px;
}
.item-info p {
    font-size: 13px;
}
/* ===========
  Sweet Alert
 =============*/
.sweet-alert h2 {
    font-size: 22px;
}
.sweet-alert p {
    font-size: 14px;
    line-height: 22px;
}
.sweet-alert .icon.success .placeholder {
    border: 4px solid rgba(0, 177, 157, 0.3);
}
.sweet-alert .icon.success .line {
    background-color: #00b19d;
}
.sweet-alert .icon.warning {
    border-color: #ffaa00;
}
.sweet-alert .icon.info {
    border-color: #6cc6d6;
}
.sweet-alert .btn-warning:focus,
.sweet-alert .btn-info:focus,
.sweet-alert .btn-success:focus,
.sweet-alert .btn-danger:focus,
.sweet-alert .btn-default:focus {
    box-shadow: none;
}
/* =============
   Notification
============= */
.notifyjs-metro-base {
    position: relative;
    min-height: 52px;
    min-width: 250px;
    color: #444;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.2);
    -webkit-animation: dropdownOpen 0.3s ease-out;
    -o-animation: dropdownOpen 0.3s ease-out;
    animation: dropdownOpen 0.3s ease-out;
}
.notifyjs-metro-base .image {
    display: table;
    position: absolute;
    height: auto;
    width: auto;
    left: 25px;
    top: 50%;
    font-size: 24px;
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
.notifyjs-metro-base .text-wrapper {
    display: inline-block;
    vertical-align: top;
    text-align: left;
    margin: 10px 10px 10px 52px;
    clear: both;
}
.notifyjs-metro-base .title {
    font-size: 15px;
    line-height: 20px;
    margin-bottom: 5px;
    font-weight: bold;
}
.notifyjs-metro-base .text {
    font-size: 12px;
    font-weight: normal;
    max-width: 360px;
    vertical-align: middle;
}
.notifyjs-metro-cool {
    color: #fafafa !important;
    background-color: #4a525f;
    border: 1px solid #4a525f;
}
/* =============
   Nestable
============= */
.custom-dd .dd-list .dd-item .dd-handle {
    background: rgba(152, 166, 173, 0.25) !important;
    border: none;
    padding: 8px 16px;
    height: auto;
    font-weight: 600;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    -moz-border-radius: 3px;
    background-clip: padding-box;
}
.custom-dd .dd-list .dd-item .dd-handle:hover {
    color: #3bafda;
}
.custom-dd .dd-list .dd-item button {
    height: auto;
    font-size: 17px;
    margin: 8px auto;
    color: #555555;
    width: 30px;
}
.custom-dd-empty .dd-list .dd3-handle {
    border: none;
    background: rgba(152, 166, 173, 0.25) !important;
    height: 36px;
    width: 36px;
}
.custom-dd-empty .dd-list .dd3-handle:before {
    color: inherit;
    top: 7px;
}
.custom-dd-empty .dd-list .dd3-handle:hover {
    color: #3bafda;
}
.custom-dd-empty .dd-list .dd3-content {
    height: auto;
    border: none;
    padding: 8px 16px 8px 46px;
    background: rgba(152, 166, 173, 0.25) !important;
    font-weight: 600;
}
.custom-dd-empty .dd-list .dd3-content:hover {
    color: #3bafda;
}
.custom-dd-empty .dd-list button {
    width: 26px;
    height: 26px;
    font-size: 16px;
    font-weight: 600;
}
/* ===========
  Maps
 =============*/
.gmaps,
.gmaps-panaroma {
    height: 300px;
    background: #eeeeee;
    border-radius: 3px;
}
.gmaps-overlay {
    display: block;
    text-align: center;
    color: #ffffff;
    font-size: 16px;
    line-height: 40px;
    background: #3bafda;
    border-radius: 4px;
    padding: 10px 20px;
}
.gmaps-overlay_arrow {
    left: 50%;
    margin-left: -16px;
    width: 0;
    height: 0;
    position: absolute;
}
.gmaps-overlay_arrow.above {
    bottom: -15px;
    border-left: 16px solid transparent;
    border-right: 16px solid transparent;
    border-top: 16px solid #3bafda;
}
.gmaps-overlay_arrow.below {
    top: -15px;
    border-left: 16px solid transparent;
    border-right: 16px solid transparent;
    border-bottom: 16px solid #3bafda;
}
/* =============
   Email
============= */
.mails a {
    color: #797979;
}
.mails td {
    vertical-align: middle !important;
    position: relative;
    border-top: 1px solid #f5f5f5 !important;
}
.mails td:last-of-type {
    width: 100px;
    padding-right: 20px;
}
.mails tr:hover .text-white {
    display: none;
}
.mails .mail-select {
    padding: 12px 20px;
    min-width: 134px;
}
.mails .checkbox {
    margin-bottom: 0px;
    margin-top: 0px;
    vertical-align: middle;
    display: inline-block;
    height: 17px;
}
.mails .checkbox label {
    min-height: 16px;
}
.mail-list .list-group-item {
    background-color: transparent;
}
.mail-list .list-group-item:hover {
    background-color: #eeeeee;
}
.mail-list .list-group-item:focus {
    background-color: #eeeeee;
}
.mail-list .list-group-item.active {
    background-color: #eeeeee;
    color: #4c5667;
    font-weight: 600;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    -moz-border-radius: 3px;
    background-clip: padding-box;
}
.unread a {
    font-weight: 600;
    color: #444444;
}
/* =============
   Form Advanced
============= */
.bootstrap-tagsinput {
    box-shadow: none;
    padding: 3px 7px 6px;
    border: 1px solid #e3e3e3;
}
.bootstrap-tagsinput .label-info {
    background-color: #3bafda !important;
    display: inline-block;
    padding: 5px;
}
/* Multiple */
.ms-container {
    background: transparent url('/images/multiple-arrow.png') no-repeat 50% 50%;
}
.ms-container .ms-list {
    box-shadow: none;
    border: 1px solid #e3e3e3;
}
.ms-container .ms-list.ms-focus {
    box-shadow: none;
    border: 1px solid #aaaaaa;
}
.ms-container .ms-selectable li.ms-elem-selectable {
    border: none;
    padding: 5px 10px;
}
.ms-container .ms-selection li.ms-elem-selection {
    border: none;
    padding: 5px 10px;
}
.select2-container .select2-choice {
    background-image: none !important;
    border: none !important;
    height: auto !important;
    padding: 0px !important;
    line-height: 22px !important;
    background-color: transparent !important;
    box-shadow: none !important;
}
.select2-container .select2-choice .select2-arrow {
    background-image: none !important;
    background: transparent;
    border: none;
    width: 14px;
    top: -2px;
}
.select2-container .select2-container-multi.form-control {
    height: auto;
}
.select2-results .select2-highlighted {
    color: #ffffff;
    background-color: #3bafda;
}
.select2-drop-active {
    border: 1px solid #e3e3e3 !important;
    padding-top: 5px;
    -webkit-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
}
.select2-search input {
    border: 1px solid #e3e3e3;
}
.select2-container-multi {
    width: 100%;
}
.select2-container-multi .select2-choices {
    border: 1px solid #e3e3e3 !important;
    box-shadow: none !important;
    background-image: none !important;
    -webkit-border-radius: 4px !important;
    border-radius: 4px !important;
    -moz-border-radius: 4px !important;
    background-clip: padding-box !important;
    min-height: 38px;
}
.select2-container-multi .select2-choices .select2-search-choice {
    padding: 4px 7px 4px 18px;
    margin: 5px 0 3px 5px;
    color: #555555;
    background: #f5f5f5;
    border-color: #e5e5e5;
    -webkit-box-shadow: none;
    box-shadow: none;
}
.select2-container-multi .select2-choices .select2-search-field input {
    padding: 7px 7px 7px 10px;
    font-family: inherit;
}
/* Wysiwig */
.mce-content-body p {
    color: #9398a0;
    font-size: 14px;
    font-weight: 300;
}
.mce-popover .mce-arrow:after {
    border-bottom-color: red;
}
.mce-popover .mce-colorbutton-grid {
    margin: 0px;
    border: 1px solid #d7dce5 !important;
    padding: 4px;
}
.mce-reset .mce-window-head {
    border-bottom: 1px solid #d7dce5;
}
.mce-reset .mce-window-head .mce-title {
    color: #707780;
    font-size: 16px;
    font-weight: 400;
}
.mce-reset .mce-textbox {
    border-radius: 0px;
    box-shadow: none;
    outline: 0;
    border-color: #d7dce5;
    height: 30px;
    font-weight: 300;
    line-height: 30px;
    color: #aaaaaa;
    font-size: 14px;
}
.mce-reset .mce-textbox:focus {
    box-shadow: none;
    border-color: #3bafda;
}
.mce-reset .mce-checkbox .mce-ico {
    background-image: none;
    background-color: #ffffff;
    border-radius: 0px;
    border: 1px solid #d7dce5;
}
.mce-reset .mce-checkbox .mce-label {
    color: #707780;
    font-size: 12px;
    font-weight: 400;
}
.mce-container {
    border-radius: 0px !important;
    border-width: 0px !important;
}
.mce-container .mce-menubar {
    background-color: #f2f4f7 !important;
    border: 1px solid #d7dce5 !important;
    padding: 2px;
}
.mce-container .mce-menubar .mce-btn button span {
    color: #707780;
    font-size: 14px;
    font-weight: 400;
    text-transform: capitalize;
}
.mce-container .mce-menubar .mce-btn button .mce-caret {
    border-top-color: #707780;
}
.mce-container .mce-menubar .mce-btn button:hover {
    background-color: #e8ebf1;
}
.mce-container .mce-menubar .mce-btn.mce-active button {
    background-color: #e8ebf1;
}
.mce-container .mce-btn {
    background-color: #d7dce5;
    background-image: none;
    outline: 0;
    border: 0px;
    border-radius: 0px;
}
.mce-container .mce-btn button {
    color: #ffffff;
    font-size: 14px;
    font-weight: 400;
    text-shadow: none;
}
.mce-container .mce-btn:hover {
    background-color: #b8c1d1;
    background-image: none;
}
.mce-container .mce-primary {
    background-color: #3bafda;
    background-image: none;
    outline: 0;
    border: 0px;
    border-radius: 0px;
}
.mce-container .mce-primary button {
    color: #ffffff;
    font-size: 14px;
    font-weight: 400;
    text-shadow: none;
}
.mce-container .mce-primary:hover {
    background-color: #0c7cd5;
    background-image: none;
}
.mce-container .mce-toolbar-grp {
    background-color: #f2f4f7 !important;
    border: 1px solid #d7dce5 !important;
    border-top-width: 0px !important;
    padding: 6px;
}
.mce-container .mce-edit-area {
    border: 1px solid #d7dce5 !important;
    border-width: 0px 1px !important;
}
.mce-container .mce-statusbar {
    background-color: #f2f4f7 !important;
    border: 1px solid #d7dce5 !important;
}
.mce-container .mce-statusbar .mce-path .mce-path-item {
    color: #707780;
    font-size: 14px;
    font-weight: 400;
}
.mce-container .mce-widget {
    color: #9398a0;
    font-size: 14px;
    font-weight: 400;
    border-left: 1px solid transparent;
}
.mce-container .mce-btn-group {
    border: 1px solid #e9ecf2 !important;
}
.mce-container .mce-btn-group .mce-btn {
    box-shadow: none;
    background-image: none;
    background-color: #ffffff;
    border-width: 0px;
    border-radius: 0px !important;
}
.mce-container .mce-btn-group .mce-btn:hover,
.mce-container .mce-btn-group .mce-btn:focus {
    box-shadow: none;
    background-image: none;
    background-color: #ffffff;
}
.mce-container .mce-btn-group .mce-btn button span {
    color: #707780;
    font-size: 14px;
    font-weight: 300;
}
.mce-container .mce-btn-group .mce-btn button .mce-caret {
    color: #707780;
    font-size: 14px;
}
.mce-container .mce-ico {
    color: #707780;
    font-size: 14px;
}
.mce-container .mce-panel {
    background-image: none;
}
.mce-container.mce-menu {
    border: 1px solid #d7dce5 !important;
}
.mce-container.mce-menu .mce-menu-item {
    background-image: none;
}
.mce-container.mce-menu .mce-menu-item .mce-ico {
    color: #3bafda;
    font-size: 14px;
}
.mce-container.mce-menu .mce-menu-item .mce-text {
    color: #707780;
    font-size: 14px;
    font-weight: 400;
    text-transform: capitalize;
}
.mce-container.mce-menu .mce-menu-item .mce-menu-shortcut {
    color: #aaaaaa;
    font-size: 12px;
    font-weight: 300;
    text-transform: capitalize;
}
.mce-container.mce-menu .mce-menu-item:hover,
.mce-container.mce-menu .mce-menu-item:focus,
.mce-container.mce-menu .mce-menu-item.mce-selected {
    background-color: #3bafda;
}
.mce-container.mce-menu .mce-menu-item:hover .mce-ico,
.mce-container.mce-menu .mce-menu-item:focus .mce-ico,
.mce-container.mce-menu .mce-menu-item.mce-selected .mce-ico,
.mce-container.mce-menu .mce-menu-item:hover .mce-text,
.mce-container.mce-menu .mce-menu-item:focus .mce-text,
.mce-container.mce-menu .mce-menu-item.mce-selected .mce-text,
.mce-container.mce-menu .mce-menu-item:hover .mce-menu-shortcut,
.mce-container.mce-menu .mce-menu-item:focus .mce-menu-shortcut,
.mce-container.mce-menu .mce-menu-item.mce-selected .mce-menu-shortcut {
    color: #ffffff;
}
.mce-container.mce-menu .mce-menu-item.mce-disabled .mce-ico,
.mce-container.mce-menu .mce-menu-item.mce-disabled .mce-text,
.mce-container.mce-menu .mce-menu-item.mce-disabled .mce-menu-shortcut {
    color: #aaaaaa;
}
.mce-container.mce-menu .mce-menu-item.mce-disabled:hover,
.mce-container.mce-menu .mce-menu-item.mce-disabled:focus,
.mce-container.mce-menu .mce-menu-item.mce-disabled.mce-selected {
    background-color: #d7dce5;
}
.mce-container.mce-menu .mce-menu-item.mce-disabled:hover .mce-ico,
.mce-container.mce-menu .mce-menu-item.mce-disabled:focus .mce-ico,
.mce-container.mce-menu .mce-menu-item.mce-disabled.mce-selected .mce-ico,
.mce-container.mce-menu .mce-menu-item.mce-disabled:hover .mce-text,
.mce-container.mce-menu .mce-menu-item.mce-disabled:focus .mce-text,
.mce-container.mce-menu .mce-menu-item.mce-disabled.mce-selected .mce-text,
.mce-container.mce-menu .mce-menu-item.mce-disabled:hover .mce-menu-shortcut,
.mce-container.mce-menu .mce-menu-item.mce-disabled:focus .mce-menu-shortcut,
.mce-container.mce-menu .mce-menu-item.mce-disabled.mce-selected .mce-menu-shortcut {
    color: #ffffff;
}
.mce-container.mce-menu .mce-menu-item-sep {
    background-color: #d7dce5;
}
.mce-container.mce-menu .mce-menu-item-sep:hover {
    background-color: #d7dce5;
}
.mce-menubtn button {
    color: #797979 !important;
}
.mce-menu-item-normal.mce-active {
    background-color: #3bafda !important;
}
.mce-menu-item-normal.mce-active .mce-text {
    color: #ffffff !important;
}
/* =============
   Charts
============= */
.morris-hover.morris-default-style {
    border-radius: 5px;
    padding: 10px 12px;
    background: #36404a;
    border: none;
    color: #ffffff !important;
}
.chart-detail-list li {
    margin: 0px 10px;
}
.chart-detail-list li h5 {
    font-size: 15px;
}
.pieLabel div {
    font-size: 14px !important;
}
.jqstooltip {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
}
.chart {
    position: relative;
    display: inline-block;
    width: 110px;
    height: 110px;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
}
.chart canvas {
    position: absolute;
    top: 0;
    left: 0;
}
.chart.chart-widget-pie {
    margin-top: 5px;
    margin-bottom: 5px;
}
.percent {
    display: inline-block;
    line-height: 110px;
    z-index: 2;
    font-weight: 600;
    font-size: 18px;
    color: #797979;
}
.percent:after {
    content: '%';
    margin-left: 0.1em;
    font-size: 0.8em;
}
/* Flot chart */
#flotTip {
    padding: 8px 12px;
    background-color: #36404a;
    z-index: 100;
    color: #ffffff;
    opacity: 0.9;
    font-size: 13px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
}
.legend tr {
    height: 20px;
}
.legendLabel {
    padding-left: 5px !important;
    line-height: 10px;
    padding-right: 10px;
}
/* Chartist chart */
.ct-golden-section:before {
    float: none;
}
.ct-chart {
    max-height: 300px;
}
.ct-chart .ct-label {
    fill: #a3afb7;
    color: #a3afb7;
    font-size: 12px;
    line-height: 1;
}
.ct-chart.simple-pie-chart-chartist .ct-label {
    color: #ffffff;
    fill: #ffffff;
    font-size: 16px;
}
.ct-chart .ct-series.ct-series-a .ct-bar,
.ct-chart .ct-series.ct-series-a .ct-line,
.ct-chart .ct-series.ct-series-a .ct-point,
.ct-chart .ct-series.ct-series-a .ct-slice-donut {
    stroke: #3bafda;
}
.ct-chart .ct-series.ct-series-b .ct-bar,
.ct-chart .ct-series.ct-series-b .ct-line,
.ct-chart .ct-series.ct-series-b .ct-point,
.ct-chart .ct-series.ct-series-b .ct-slice-donut {
    stroke: #f76397;
}
.ct-chart .ct-series.ct-series-c .ct-bar,
.ct-chart .ct-series.ct-series-c .ct-line,
.ct-chart .ct-series.ct-series-c .ct-point,
.ct-chart .ct-series.ct-series-c .ct-slice-donut {
    stroke: #00b19d;
}
.ct-chart .ct-series.ct-series-d .ct-bar,
.ct-chart .ct-series.ct-series-d .ct-line,
.ct-chart .ct-series.ct-series-d .ct-point,
.ct-chart .ct-series.ct-series-d .ct-slice-donut {
    stroke: #6cc6d6;
}
.ct-chart .ct-series.ct-series-e .ct-bar,
.ct-chart .ct-series.ct-series-e .ct-line,
.ct-chart .ct-series.ct-series-e .ct-point,
.ct-chart .ct-series.ct-series-e .ct-slice-donut {
    stroke: #797979;
}
.ct-chart .ct-series.ct-series-f .ct-bar,
.ct-chart .ct-series.ct-series-f .ct-line,
.ct-chart .ct-series.ct-series-f .ct-point,
.ct-chart .ct-series.ct-series-f .ct-slice-donut {
    stroke: #7266ba;
}
.ct-chart .ct-series.ct-series-g .ct-bar,
.ct-chart .ct-series.ct-series-g .ct-line,
.ct-chart .ct-series.ct-series-g .ct-point,
.ct-chart .ct-series.ct-series-g .ct-slice-donut {
    stroke: #ffaa00;
}
.ct-series-a .ct-area,
.ct-series-a .ct-slice-pie {
    fill: #3bafda;
}
.ct-series-b .ct-area,
.ct-series-b .ct-slice-pie {
    fill: #f76397;
}
.ct-series-c .ct-area,
.ct-series-c .ct-slice-pie {
    fill: #00b19d;
}
.ct-series-d .ct-area,
.ct-series-d .ct-slice-pie {
    fill: #6cc6d6;
}
/* Sparkline chart */
.jqstooltip {
    background-color: #36404a !important;
    padding: 5px 10px !important;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    -moz-border-radius: 3px;
    background-clip: padding-box;
    border-color: #36404a !important;
}
.jqsfield {
    font-size: 12px !important;
    line-height: 18px !important;
}
/* Circliful charts */
.circliful-chart {
    margin: 0px auto;
}
.circle-text,
.circle-info,
.circle-text-half,
.circle-info-half {
    font-size: 12px;
    font-weight: 600;
}
/* ===========
   Count Down
 =============*/
.home-wrapper {
    margin: 10% 0px;
}
.app-countdown {
    margin-top: 40px;
    text-align: center;
}
.app-countdown div {
    display: inline-block;
}
.app-countdown div span {
    display: block;
    width: 150px;
}
.app-countdown div span:first-child {
    font-size: 3em;
    font-weight: 700;
    height: 48px;
    line-height: 48px;
}
.app-countdown div span:last-child {
    color: #333333;
    font-size: 0.9em;
    height: 25px;
    line-height: 25px;
}
.app-countdown > * {
    text-align: center;
}
/* ===========
   Gallery
 =============*/
.portfolioFilter a {
    -moz-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    -webkit-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
    -webkit-transition: all 0.3s ease-out;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
    color: #333333;
    padding: 5px 10px;
    display: inline-block;
    transition: all 0.3s ease-out;
}
.portfolioFilter a:hover {
    background-color: #3bafda;
    color: #ffffff;
}
.portfolioFilter a.current {
    background-color: #3bafda;
    color: #ffffff;
}
.thumb {
    background-color: #ffffff;
    border-radius: 3px;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
    margin-top: 30px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    width: 100%;
}
.thumb-img {
    border-radius: 2px;
    overflow: hidden;
    width: 100%;
}
.gal-detail h4 {
    margin: 16px auto 10px auto;
    width: 80%;
    white-space: nowrap;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
}
.gal-detail .ga-border {
    height: 3px;
    width: 40px;
    background-color: #3bafda;
    margin: 10px auto;
}
/* ===========
   Maintenance
 =============*/
.icon-main {
    font-size: 60px;
}
.maintenance-page {
    margin: 10% 0%;
}
.home-text {
    letter-spacing: 1px;
}
/* ===========
   Accounts
 =============*/
.wrapper-page {
    margin: auto;
    max-width: 360px;
}
.wrapper-page .form-control {
    height: 40px;
    padding-left: 40px;
}
.wrapper-page .form-control-feedback {
    left: 15px;
    top: 3px;
    color: rgba(76, 86, 103, 0.4);
    font-size: 20px;
}
.wrapper-page .btn-email {
    padding: 9px 20px;
}
.logo-lg {
    font-size: 26px !important;
    font-weight: 600;
    line-height: 70px;
    color: #3bafda !important;
}
.user-thumb img {
    height: 88px;
    margin: 0px auto;
    width: 88px;
}
.ex-page-content .svg-box {
    float: right;
}
.message-box {
    margin-top: 120px;
    margin-left: 50px;
    font-weight: 300;
}
.message-box h1 {
    color: #252932;
    font-size: 98px;
    font-weight: 700;
    line-height: 98px;
    text-shadow: rgba(61, 61, 61, 0.3) 1px 1px, rgba(61, 61, 61, 0.2) 2px 2px, rgba(61, 61, 61, 0.3) 3px 3px;
}
#Polygon-1,
#Polygon-2,
#Polygon-3,
#Polygon-4,
#Polygon-4,
#Polygon-5 {
    animation: float 1s infinite ease-in-out alternate;
}
#Polygon-2 {
    animation-delay: 0.2s;
}
#Polygon-3 {
    animation-delay: 0.4s;
}
#Polygon-4 {
    animation-delay: 0.6s;
}
#Polygon-5 {
    animation-delay: 0.8s;
}
@keyframes float {
    100% {
        transform: translateY(20px);
    }
}
/* =============
   Tree view page
============= */
.jstree-default .jstree-clicked,
.jstree-default .jstree-wholerow-clicked {
    background: rgba(59, 175, 218, 0.4);
    box-shadow: none;
}
.jstree-default .jstree-hovered,
.jstree-default .jstree-wholerow-hovered {
    background: rgba(59, 175, 218, 0.2);
    box-shadow: none;
}
.jstree-default .zmdi {
    font-size: 16px;
}
/* =============
   Pricing
============= */
.pricing-column {
    position: relative;
    margin-bottom: 40px;
}
.pricing-column .inner-box {
    position: relative;
    padding: 0 0 50px;
}
.pricing-column .plan-header {
    position: relative;
    padding: 30px 20px 25px;
}
.pricing-column .plan-title {
    font-size: 16px;
    margin-bottom: 10px;
    color: #00b19d;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 400;
}
.pricing-column .plan-price {
    font-size: 48px;
    margin-bottom: 10px;
    color: #2a3142;
}
.pricing-column .plan-duration {
    font-size: 13px;
    color: #98a6ad;
}
.pricing-column .plan-stats {
    position: relative;
    padding: 30px 20px 15px;
}
.pricing-column .plan-stats li {
    margin-bottom: 15px;
    line-height: 24px;
}
.pricing-column .plan-stats li i {
    font-size: 16px;
    vertical-align: middle;
    margin-right: 5px;
}
.ribbon {
    position: absolute;
    left: 5px;
    top: -5px;
    z-index: 1;
    overflow: hidden;
    width: 75px;
    height: 75px;
    text-align: right;
}
.ribbon span {
    font-size: 10px;
    font-weight: bold;
    color: #ffffff;
    text-transform: uppercase;
    text-align: center;
    line-height: 20px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    width: 100px;
    display: block;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.06), 0 1px 0 0 rgba(0, 0, 0, 0.02);
    background: #00b19d;
    background: linear-gradient(#00b19d 0%, #00b19d 100%);
    position: absolute;
    top: 19px;
    letter-spacing: 1px;
    left: -21px;
}
.ribbon span:before {
    content: '';
    position: absolute;
    left: 0;
    top: 100%;
    z-index: -1;
    border-left: 3px solid #007e70;
    border-right: 3px solid transparent;
    border-bottom: 3px solid transparent;
    border-top: 3px solid #007e70;
}
.ribbon span:after {
    content: '';
    position: absolute;
    right: 0;
    top: 100%;
    z-index: -1;
    border-left: 3px solid transparent;
    border-right: 3px solid #007e70;
    border-bottom: 3px solid transparent;
    border-top: 3px solid #007e70;
}
/* =============
   FAQ
============= */
.question-q-box {
    height: 30px;
    width: 30px;
    color: #ffffff;
    background-color: #ef5350;
    text-align: center;
    border-radius: 50%;
    float: left;
    line-height: 30px;
    font-weight: 700;
}
.question {
    margin-top: 0;
    margin-left: 50px;
    font-weight: 400;
    font-size: 16px;
}
.answer {
    margin-left: 50px;
    color: #98a6ad;
    margin-bottom: 40px;
    line-height: 26px;
}
