// z-index levels used through app
$z-index-map-popup: 3;

$color-active: #3bafda;
$color-inactive: #c8c8c8;
$color-hover: #7aa3cc;

// CA branding
$ca-branding: (
    default: #dae6ec,
    primary: #3569e0,
    secondary: #254e77,
    warning: #ec971f,
    error: #ef5350,
    info: #60a5fa,
    success: #23b481,
    dark-blue: #113259,
    darkest-blue: #181f2f,
    light-yellow: #ffc,
    text-color: #333,
    text-color-dark: #113259,
    lightest-gray: #eee,
    light-gray: #ccc,
    dark-gray: #666,
    body-bg: #f5f5f5,
    pink: #f66d9b,
    purple: #9561e2,
    inverse: #113259
);

// Modals
$modal: (
    overlay-bg-color: rgba(map-get($ca-branding, 'darkest-blue'), 0.9),
    header-text-color: lighten(#505458, 0.2),
    content-bg-color: #f2f4f4,
    content-border-color: darken(map-get($ca-branding, 'dark-blue'), 2%)
);

// Header
$header-height: 121px;

// Sidebar
$sidebar-width: 420px;
$sidebar-width-mobile: 320px;

// Breakpoints
$breakpoints: (
    xsmall: 320px,
    small: 576px,
    medium: 768px,
    large: 992px,
    xlarge: 1200px
);

/**
 *  Bootstrap
 */

// map bootstrap theme to $ca-branding
$brand-default: map-get($ca-branding, 'default');
$brand-primary: map-get($ca-branding, 'primary');
$brand-secondary: map-get($ca-branding, 'secondary');
$brand-success: map-get($ca-branding, 'success');
$brand-info: map-get($ca-branding, 'info');
$brand-warning: map-get($ca-branding, 'warning');
$brand-danger: map-get($ca-branding, 'error');
$gray-light: map-get($ca-branding, 'light-gray');
$gray-lighter: map-get($ca-branding, 'lightest-gray');
$gray-dark: map-get($ca-branding, 'dark-gray');
$text-color: map-get($ca-branding, 'text-color');
$text-dark-color: map-get($ca-branding, 'text-dark-color');
$darkest-blue: map-get($ca-branding, 'darkest-blue');
$body-bg: map-get($ca-branding, 'body-bg');
$pink: map-get($ca-branding, 'pink');
$purple: map-get($ca-branding, 'purple');
$inverse: map-get($ca-branding, 'inverse');

//== Buttons
//
//## For each of Bootstrap's buttons, define text, background and border color.

$btn-font-weight: normal;

$btn-default-color: $text-color;
$btn-default-bg: $brand-default;
$btn-default-border: $brand-default;

$btn-primary-color: #fff;
$btn-primary-bg: $brand-primary;
$btn-primary-border: darken($btn-primary-bg, 5%);

$btn-success-color: #fff;
$btn-success-bg: $brand-success;
$btn-success-border: darken($btn-success-bg, 5%);

$btn-info-color: #fff;
$btn-info-bg: $brand-info;
$btn-info-border: darken($btn-info-bg, 5%);

$btn-warning-color: #fff;
$btn-warning-bg: $brand-warning;
$btn-warning-border: darken($btn-warning-bg, 5%);

$btn-danger-color: #fff;
$btn-danger-bg: $brand-danger;
$btn-danger-border: darken($btn-danger-bg, 5%);

$btn-link-disabled-color: $gray-light;

//== Media queries breakpoints'
$screen-xs: map-get($breakpoints, 'xsmall');
$screen-xs-min: $screen-xs;
$screen-xs-max: ($screen-xs-min - 1);

$screen-sm: map-get($breakpoints, 'small');
$screen-sm-min: $screen-sm;
$screen-sm-max: ($screen-sm-min - 1);

$screen-md: map-get($breakpoints, 'medium');
$screen-md-min: $screen-md;
$screen-md-max: ($screen-md-min - 1);

$screen-lg: map-get($breakpoints, 'large');
$screen-lg-min: $screen-lg;
$screen-lg-max: ($screen-lg-min - 1);

$screen-xl: map-get($breakpoints, 'xlarge');
$screen-xl-min: $screen-xl;
$screen-xl-max: ($screen-xl-min - 1);

//** Point at which the navbar becomes uncollapsed.
$grid-float-breakpoint: $screen-lg-min;

//** Point at which the navbar begins collapsing.
$grid-float-breakpoint-max: ($grid-float-breakpoint - 1);

//** `<input>` background color
$input-bg: #fff;

//** `<input disabled>` background color
$input-bg-disabled: $gray-lighter;

//** Text color for `<input>`s
$input-color: $text-color;

//** `<input>` border color
$input-border: $gray-lighter;

$input-bg-error: #fff4f2;
