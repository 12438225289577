.border {
    border: 1px solid #dee2e6 !important;
}

.border-0 {
    border: 0 !important;
}

.border-top {
    border-top: 1px solid #dee2e6 !important;
}

.border-top-0 {
    border-top: 0 !important;
}

.border-right {
    border-right: 1px solid #dee2e6 !important;
}

.border-right-0 {
    border-right: 0 !important;
}

.border-bottom {
    border-bottom: 1px solid #dee2e6 !important;
}

.border-bottom-0 {
    border-bottom: 0 !important;
}

.border-left {
    border-left: 1px solid #dee2e6 !important;
}

.border-left-0 {
    border-left: 0 !important;
}

.border-primary {
    border-color: $brand-primary !important;
}

.border-secondary {
    border-color: $brand-secondary !important;
}

.border-success {
    border-color: $brand-success !important;
}

.border-info {
    border-color: $brand-info !important;
}

.border-warning {
    border-color: $brand-warning !important;
}

.border-danger {
    border-color: $brand-danger !important;
}

.border-light {
    border-color: $gray-light !important;
}

.border-dark {
    border-color: $gray-dark !important;
}

.border-white {
    border-color: #fff !important;
}

.rounded {
    border-radius: 0.25rem !important;
}

.rounded-sm {
    border-radius: 0.2rem !important;
}

.rounded-lg {
    border-radius: 0.3rem !important;
}

.rounded-xl {
    border-radius: 2.3rem !important;
}

.rounded-circle {
    border-radius: 50% !important;
}

.rounded-pill {
    border-radius: 50rem !important;
}

.rounded-0 {
    border-radius: 0 !important;
}

.rounded-top {
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
}

.rounded-right {
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
    border-bottom-right-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
    border-bottom-left-radius: 0.25rem !important;
    border-top-left-radius: 0.25rem !important;
}

// legacy
.b-0 {
    border: none !important;
}

.no-border {
    border: none !important;
}
