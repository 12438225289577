.bg-primary {
    background-color: $brand-primary !important;
}

.bg-secondary {
    background-color: $brand-secondary !important;
}

.bg-success {
    background-color: $brand-success !important;
}

.bg-info {
    background-color: $brand-info !important;
}

.bg-warning {
    background-color: $brand-warning !important;
}

.bg-danger {
    background-color: $brand-danger !important;
}

.bg-light {
    background-color: $gray-light !important;
}

.bg-dark {
    background-color: $darkest-blue !important;
}

.bg-body {
    background-color: $body-bg !important;
}

.bg-white {
    background-color: #fff !important;
}

.bg-transparent {
    background-color: transparent !important;
}

.text-brand-primary {
    color: $brand-primary;
}
