/* ===========
   Widgets
 =============*/
.inbox-widget .inbox-item {
    border-bottom: 1px solid #f1f1f1;
    overflow: hidden;
    padding: 10px 0;
    position: relative;
}
.inbox-widget .inbox-item .inbox-item-img {
    display: block;
    float: left;
    margin-right: 15px;
    width: 40px;
}
.inbox-widget .inbox-item img {
    width: 40px;
}
.inbox-widget .inbox-item .inbox-item-author {
    color: #333333;
    display: block;
    margin: 0;
}
.inbox-widget .inbox-item .inbox-item-text {
    color: #a0a0a0;
    display: block;
    font-size: 12px;
    margin: 0;
}
.inbox-widget .inbox-item .inbox-item-date {
    color: #a9a9a9;
    font-size: 11px;
    position: absolute;
    right: 7px;
    top: 2px;
}
.conversation-list {
    list-style: none;
    max-height: 330px;
    padding: 0px 20px;
}
.conversation-list li {
    margin-bottom: 24px;
}
.conversation-list .chat-avatar {
    display: inline-block;
    float: left;
    text-align: center;
    width: 40px;
}
.conversation-list .chat-avatar img {
    border-radius: 100%;
    width: 100%;
}
.conversation-list .chat-avatar i {
    font-size: 12px;
    font-style: normal;
}
.conversation-list .ctext-wrap {
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    background: #f5f5f5;
    border-radius: 3px;
    display: inline-block;
    padding: 10px;
    position: relative;
}
.conversation-list .ctext-wrap i {
    color: #1a2942;
    display: block;
    font-size: 12px;
    font-style: normal;
    font-weight: bold;
    position: relative;
}
.conversation-list .ctext-wrap p {
    margin: 0px;
    padding-top: 3px;
}
.conversation-list .ctext-wrap:after {
    right: 100%;
    top: 20%;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(213, 242, 239, 0);
    border-right-color: #f5f5f5;
    border-width: 5px;
    margin-top: -5px;
}
.conversation-list .conversation-text {
    display: inline-block;
    float: left;
    font-size: 12px;
    margin-left: 12px;
    width: 70%;
}
.conversation-list .odd .chat-avatar {
    float: right !important;
}
.conversation-list .odd .conversation-text {
    float: right !important;
    margin-right: 12px;
    text-align: right;
    width: 70% !important;
}
.conversation-list .odd .ctext-wrap {
    background: #e8e9ec !important;
}
.conversation-list .odd .ctext-wrap i {
    color: #acacac;
}
.conversation-list .odd .ctext-wrap:after {
    border-color: rgba(238, 238, 242, 0) !important;
    border-left-color: #e8e9ec !important;
    left: 100% !important;
    top: 20% !important;
}
.chat-send {
    padding-left: 0px;
    padding-right: 30px;
}
.chat-send button {
    width: 100%;
}
.chat-inputbar {
    padding-left: 30px;
}
#todo-message {
    font-size: 16px;
}
.todo-list li {
    border: 0px;
    margin: 0px;
    padding-left: 0px !important;
    padding-right: 0px !important;
    background: transparent !important;
}
.todo-list li:last-of-type {
    border-bottom: none;
}
.todo-send {
    padding-left: 0px;
}
/* Widget-chart */
.widget-chart ul li {
    width: 31.5%;
    display: inline-block;
    padding: 0px;
}
.widget-panel {
    padding: 30px 20px;
    padding-left: 30px;
    border-radius: 4px;
    position: relative;
    margin-bottom: 20px;
}
.widget-panel i {
    font-size: 60px;
    padding: 30px;
    background: rgba(255, 255, 255, 0.2);
    position: absolute;
    right: 0px;
    bottom: 0px;
    top: 0px;
    line-height: 60px;
}
.widget-user {
    min-height: 112px;
}
.widget-user img {
    height: 72px;
    float: left;
}
.widget-user .wid-u-info {
    margin-left: 90px;
}
.widget-user .wid-u-info p {
    white-space: nowrap;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
}
.widget-simple-chart .circliful-chart {
    float: left;
    margin-top: -5px;
}
.widget-icon i {
    float: left;
    font-size: 48px;
}
.widget-icon .wid-icon-info {
    margin-left: 80px;
}
