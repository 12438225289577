.multiselect {
    .option__desc {
        .option__title {
            display: block;
            line-height: 1.2;
        }

        .option__small {
            @extend .text-muted;
            font-size: 90%;
        }
    }

    .multiselect__tags,
    .multiselect__single {
        background-color: #fafafa;
    }

    .multiselect__option {
        &--highlight {
            .option__desc .option__small {
                color: white !important;
            }
        }
    }

    &--active {
        .multiselect__tags,
        .multiselect__single {
            background-color: white;
            border: 1px solid #e0e0e0;
        }
    }

    ::placeholder {
        @extend .text-muted;
        font-size: 90%;
    }
}

.has-error .multiselect__tags {
    border-color: #a94442;
    background-color: #fff4f2;
}

.has-error .multiselect__tags > input,
.has-error .multiselect__tags > .multiselect__single {
    background-color: #fff4f2;
}
