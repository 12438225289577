.ca-modal.modal {
    animation-duration: 0.3s;

    .modal-dialog {
        animation-duration: 0.2s;

        .modal-content {
            border: 1px solid map-get($modal, 'content-border-color');
            background-color: map-get($modal, 'content-bg-color');

            .modal-header {
                display: flex;
                flex-direction: row;
                align-content: center;
                justify-content: space-between;
                flex-wrap: nowrap;
                color: white;
                border-bottom: none;

                &:before,
                &:after {
                    // fix bootraps whacky clearfix
                    display: none;
                }

                p {
                    color: map-get($modal, 'header-text-color');
                }

                .close {
                    font-size: 32px;
                    margin-left: 2rem;
                }
            }

            &.dark {
                // dark modal like login

                border: 1px solid darken(map-get($ca-branding, 'dark-blue'), 2%);
                background-color: map-get($ca-branding, 'darkest-blue');

                .modal-header {
                    .close {
                        color: white;
                    }
                }
            }

            // .modal-body {
            //     padding: 20px;
            // }

            .modal-footer {
                border-top: none;
                //padding-top: 0;
            }
        }
    }

    .form-group {
        padding-top: 0 !important;
    }

    &.is-visible {
        display: block !important;
        background-color: map-get($modal, 'overlay-bg-color');
    }

    .btn i {
        margin-right: 0.25rem;
    }

    .row.equal {
        display: flex;
        flex-wrap: wrap;

        div[class^='col-'] {
            display: flex;
        }

        .select-item {
            flex-shrink: 1;
        }
    }

    @media (min-width: 768px) {
        .modal-xl {
            width: 90%;
            max-width: 1200px;
        }
    }
}
