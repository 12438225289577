.text-danger {
    color: $brand-danger !important;
}

.text-muted {
    color: #98a6ad !important;
}

.text-primary {
    color: $brand-primary !important;
}

.text-warning {
    color: $brand-warning !important;
}

.text-success {
    color: $brand-success !important;
}

.text-info {
    color: $brand-info !important;
}

.text-inverse {
    color: $inverse !important;
}

.text-pink {
    color: $pink !important;
}

.text-purple {
    color: $purple !important;
}

.text-lowercase {
    text-transform: lowercase !important;
}

.text-uppercase {
    text-transform: uppercase !important;
}

.text-capitalize {
    text-transform: capitalize !important;
}

.text-left {
    text-align: left !important;
}

.text-right {
    text-align: right !important;
}

.text-center {
    text-align: center !important;
}

.text-light {
    color: $gray-light !important;
}

.text-dark {
    color: $text-dark-color !important;
}

.text-white {
    color: #fff !important;
}

.text-body {
    color: $text-color !important;
}

.text-black-50 {
    color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
    color: rgba(255, 255, 255, 0.5) !important;
}

.text-reset {
    color: inherit !important;
}
