/* ===========
   Buttons
 =============*/
.btn {
    border-radius: 2px;
    padding: 6px 14px;
    vertical-align: middle;
}
.btn > i {
    margin-right: 0.25rem;
    vertical-align: middle;
}
.btn-group-lg > .btn,
.btn-lg {
    padding: 10px 16px !important;
    font-size: 16px;
}
.btn-group-sm > .btn,
.btn-sm {
    padding: 5px 10px !important;
}
.btn-group-xs > .btn,
.btn-xs {
    padding: 1px 5px !important;
}
.btn-group .btn + .btn,
.btn-group .btn + .btn-group,
.btn-group .btn-group + .btn,
.btn-group .btn-group + .btn-group {
    margin-left: 0.5rem;
}
.btn-group.open .dropdown-toggle {
    -webkit-box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.1) inset;
    box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.1) inset;
}
.btn-primary,
.btn-success,
.btn-info,
.btn-warning,
.btn-danger,
.btn-inverse,
.btn-purple,
.btn-pink {
    color: #ffffff !important;
}
.btn-default {
    background-color: #dae6ec;
    border-color: #dae6ec;
}
.btn-default:focus {
    background-color: #dae6ec;
    border-color: #c2ced4;
}
.btn-default:hover {
    background-color: #dae6ec;
    border-color: #c2ced4;
}
.btn-default:active {
    background-color: #dae6ec;
    border-color: #c2ced4;
}
.btn-default.active,
.btn-default:active,
.open > .dropdown-toggle.btn-default {
    background-color: #dae6ec !important;
    border-color: #c2ced4 !important;
}
.btn-transparent {
    background-color: transparent;
    border-color: transparent;
}
.btn-transparent:focus {
    background-color: transparent;
    border-color: transparent;
}
.btn-transparent:hover {
    background-color: #dae6ec;
    border-color: #c2ced4;
}
.btn-transparent:active {
    background-color: #dae6ec;
    border-color: #c2ced4;
}
.btn-primary {
    background-color: #346ae3 !important;
    border: 1px solid #346ae3 !important;
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.btn-primary.focus,
.btn-primary:active,
.btn-primary:focus,
.btn-primary:hover,
.open > .dropdown-toggle.btn-primary {
    background-color: #2659ca !important;
    border: 1px solid #2659ca !important;
}
.btn-success {
    background-color: #00b19d !important;
    border: 1px solid #00b19d !important;
}
.btn-success:hover,
.btn-success:focus,
.btn-success:active,
.btn-success.active,
.btn-success.focus,
.btn-success:active,
.btn-success:focus,
.btn-success:hover,
.open > .dropdown-toggle.btn-success {
    background-color: #009886 !important;
    border: 1px solid #009886 !important;
}
.btn-info {
    background-color: #6cc6d6 !important;
    border: 1px solid #6cc6d6 !important;
}
.btn-info:hover,
.btn-info:focus,
.btn-info:active,
.btn-info.active,
.btn-info.focus,
.btn-info:active,
.btn-info:focus,
.btn-info:hover,
.open > .dropdown-toggle.btn-info {
    background-color: #25d8f6 !important;
    border: 1px solid #25d8f6 !important;
}
.btn-warning {
    background-color: #ffaa00 !important;
    border: 1px solid #ffaa00 !important;
}
.btn-warning:hover,
.btn-warning:focus,
.btn-warning:active,
.btn-warning.active,
.btn-warning.focus,
.btn-warning:active,
.btn-warning:focus,
.btn-warning:hover,
.open > .dropdown-toggle.btn-warning {
    background-color: #e69900 !important;
    border: 1px solid #e69900 !important;
}
.btn-danger {
    background-color: #ef5350 !important;
    border: 1px solid #ef5350 !important;
}
.btn-danger:active,
.btn-danger:focus,
.btn-danger:hover,
.btn-danger.active,
.btn-danger.focus,
.btn-danger:active,
.btn-danger:focus,
.btn-danger:hover,
.open > .dropdown-toggle.btn-danger {
    background-color: #ed3c39 !important;
    border: 1px solid #ed3c39 !important;
}
.btn-inverse {
    background-color: #091c2a !important;
    border: 1px solid #091c2a !important;
}
.btn-inverse:hover,
.btn-inverse:focus,
.btn-inverse:active,
.btn-inverse.active,
.btn-inverse.focus,
.btn-inverse:active,
.btn-inverse:focus,
.btn-inverse:hover,
.open > .dropdown-toggle.btn-inverse {
    background-color: #091c2a !important;
    border: 1px solid #091c2a !important;
}
.btn-purple {
    background-color: #7266ba !important;
    border: 1px solid #7266ba !important;
}
.btn-purple:hover,
.btn-purple:focus,
.btn-purple:active {
    background-color: #6254b2 !important;
    border: 1px solid #6254b2 !important;
}
.btn-pink {
    background-color: #f76397 !important;
    border: 1px solid #f76397 !important;
}
.btn-pink:hover,
.btn-pink:focus,
.btn-pink:active {
    background-color: #f64b87 !important;
    border: 1px solid #f64b87 !important;
}
.btn-custom {
    border-bottom: 3px solid transparent;
}
.btn-custom.btn-default {
    background-color: #dae6ec;
    border-bottom: 2px solid #a4b6bf !important;
}
.btn-custom.btn-primary {
    border-bottom: 2px solid #2494be !important;
}
.btn-custom.btn-success {
    border-bottom: 2px solid #007e70 !important;
}
.btn-custom.btn-info {
    border-bottom: 2px solid #08aac6 !important;
}
.btn-custom.btn-warning {
    border-bottom: 2px solid #cc8800 !important;
}
.btn-custom.btn-danger {
    border-bottom: 2px solid #c71612 !important;
}
.btn-custom.btn-inverse {
    border-bottom: 2px solid #21252c !important;
}
.btn-custom.btn-purple {
    border-bottom: 2px solid #443a80 !important;
}
.btn-custom.btn-pink {
    border-bottom: 2px solid #e80c59 !important;
}
.btn-rounded {
    border-radius: 2em;
    padding: 6px 18px;
}
.fileupload {
    overflow: hidden;
    position: relative;
}
.fileupload input.upload {
    cursor: pointer;
    filter: alpha(opacity=0);
    font-size: 20px;
    margin: 0;
    opacity: 0;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
}
