/* =========================
   == MAPS & SIDEBARS ==
   ========================= */

/* Classes related to map and sidebar layout on frontend */
/* Inspect something like v2.communityanalytics.com.au/rms/apr to see how this is used */

.section--hotspot-map {
    position: relative;
    background: #fdfefe;
    min-height: 90vh;
    min-height: calc(100vh - 106px);
}

.map-with-sidebar-bottom {
    display: flex;
    flex-direction: column-reverse;
}

.vignette:after {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: inset 0px 0px 24vw 3vw #051725;
    pointer-events: none;
    z-index: 1;
}
.vignette-inverse:after {
    box-shadow: inset 0px 0px 18vw 2vw #cee0ed;
    mix-blend-mode: overlay;
}

.sidebar-toggle {
    display: none;
    position: absolute;
    left: 25%;
    top: 0;
    background: #fdfdfd;
    padding: 6px 12px;
    font-weight: 300;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    font-size: 11px;
    transform: rotate(90deg);
    transform-origin: bottom left;
    margin: 0;
    z-index: 2;
    opacity: 1;
    box-shadow: inset 0px -4px 12px -6px rgba(17, 20, 25, 0.33);
}
.sidebar-toggle:hover {
    background: #333132;
    color: #fff;
    cursor: pointer;
}
.map-with-sidebar-right .sidebar-toggle {
    transform: rotate(-90deg);
    transform-origin: bottom right;
    left: auto;
    right: 25%;
}
#mapSidebarToggle:not(:checked) + .sidebar-toggle {
    left: 0;
    background: #333132;
    color: #fff;
}
.map-with-sidebar-right #mapSidebarToggle:not(:checked) + .sidebar-toggle {
    left: auto;
    right: 0;
}
#mapSidebarToggle:not(:checked) + .sidebar-toggle .fa {
    transform: rotate(180deg);
}
#mapSidebarToggle:not(:checked) + .sidebar-toggle span {
    display: none;
}

.map-sidebar .site-logo {
    max-width: 450px;
    width: 100%;
    height: auto !important;
}

.map-sidebar-panel {
    display: none;
}
.map-sidebar-panel.active {
    display: block;
}
.map-sidebar-panel-header {
    padding: 25px;
}
.map-sidebar-panel-header .page-title {
    color: #fff;
    font-size: 1.6em;
    font-weight: 300;
    margin: 0 auto;
    max-width: 1140px;
}
.map-sidebar-panel-body {
    color: #333132;
    padding: 25px 25px 15px;
    margin: 0 auto;
    max-width: 1190px;
}
.map-sidebar-panel-body:before {
    display: table;
    content: ' ';
}
.map-sidebar-panel-body:after {
    clear: both;
    display: table;
    content: ' ';
}
.map-sidebar .btn {
    padding: 9px 16px;
    text-align: left;
}
.map-sidebar-panel-footer {
    padding: 0 25px 5px;
    margin: 0 auto;
    max-width: 1190px;
}

.map-sidebar .accordion-container {
    margin: 20px -25px;
}
.map-sidebar .accordion-title {
    padding: 15px 25px;
}
.map-sidebar .accordion-body {
    padding: 20px 25px;
}

.hotspot-map-type-toggles {
    background: #eee;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    text-align: center;
    display: none;
}
.hotspot-map-toggle {
    display: block;
    width: 50%;
    float: left;
    padding: 10px;
    font-weight: 300;
    margin: 0;
    cursor: pointer;
}
.hotspot-map-toggle:hover {
    background: #e2e2e2;
}
#mapTypeToggleMap:checked ~ .hotspot-map-type-toggles .hotspot-map-toggle[for='mapTypeToggleMap'],
#mapTypeToggleList:checked ~ .hotspot-map-type-toggles .hotspot-map-toggle[for='mapTypeToggleList'] {
    background: #e70231;
    color: #fff;
    pointer-events: none;
}

.hotspot-map-wrapper {
    position: relative;
}
.base-map {
    width: 100%;
    height: auto;
}
.hotspot-map-layer,
.hotspot-onhover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
}

/* Hotspot hover-active (class added/removed by jQuery) */
.hotspot-map-wrapper.hover-active:after {
    content: '';
    background: #061220;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
    opacity: 0.75;
}
.hover-active {
    z-index: 6;
}

/* Map Footer */

.map-legend-list {
    padding: 15px;
}
.map-legend-list li {
    line-height: 38px;
    margin-right: 10px;
}
.map-legend-list li > * {
    vertical-align: middle;
}
.map-key-line {
    border-top: 4px solid #0059a4;
}
.map-key-box {
    width: 50px;
    height: 9px;
    border: 2px solid #fff;
}
.map-legend-hotspot {
    background: #00abe6;
    border: 1px solid #fff;
    border-radius: 50%;
    color: #fff;
    width: 24px;
    height: 24px;
    text-align: center;
    display: inline-block;
    line-height: 22px;
    font-size: 12px;
    position: relative;
}
.map-legend-hotspot .custom-icon {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 2px;
}
#popup-key {
    line-height: 2;
}

/* =========================
   == MAPBOX ==
   ========================= */

.mapbox-map {
    position: absolute;
    width: 100%;
    height: 100%;
    height: 800px;
    height: 90vh;
    height: calc(100vh - 107px);
}
.no-page-header .mapbox-map {
    height: 100vh;
}
.mapbox-map.mapboxgl-map {
    position: relative;
}

.mapbox-layer-toggles-title {
    font-size: 80%;
    text-transform: uppercase;
    opacity: 0.6;
    margin-top: 20px;
}
.mapbox-layer-toggle {
    display: none;
    cursor: pointer;
}
.mapbox-layer-toggle[type='radio']:checked + label {
    pointer-events: none;
    margin: 0;
}

.mapbox-layer-toggle[type='checkbox'] + label {
    display: block;
    background: #fff;
    padding: 7px 7px 7px 37px;
    position: relative;
    border-radius: 5px;
    font-size: 90%;
    overflow: hidden;
    opacity: 0.5;
    border: 1px solid #eee;
    color: #333132;
    font-weight: 300;
    cursor: pointer;
}
.mapbox-layer-toggle[type='checkbox'] + label:hover {
    border: 1px solid #ddd;
    cursor: pointer;
}
.mapbox-layer-toggle[type='checkbox']:checked + label,
.checkbox-select-all-toggle[type='checkbox'] + label {
    opacity: 1;
}

.unchecked-status {
    display: initial;
}
.checked-status {
    display: none;
}
.checkbox-select-all-toggle:checked + label .unchecked-status {
    display: none;
}
.checkbox-select-all-toggle:checked + label .checked-status {
    display: initial;
}
.mapbox-legend-swatch {
    display: inline-block;
    margin: 0 6px;
    border-right: 1px solid #eee;
    position: absolute;
    top: 0;
    left: -7px;
    width: 30px;
    height: 100%;
}
.mapbox-layer-toggle[type='checkbox']:checked + label .mapbox-legend-swatch:before {
    content: '';
    width: 1.4em;
    height: 1.4em;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    background: rgba(15, 34, 56, 0.4);
}
.mapbox-layer-toggle[type='checkbox'] + label .mapbox-legend-swatch:after {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-size: 0.9em;
}
.mapbox-layer-toggle[type='checkbox']:checked + label .mapbox-legend-swatch:after {
    content: '\f00c';
}

.checkbox-select-all-toggle[type='checkbox'] + label .mapbox-legend-swatch:after {
    content: '\f070' !important;
    color: #2f3847;
}
.checkbox-select-all-toggle[type='checkbox']:checked + label .mapbox-legend-swatch:before {
    display: none;
}
.checkbox-select-all-toggle[type='checkbox']:checked + label .mapbox-legend-swatch:after {
    content: '\f06e' !important;
}

.mapboxgl-map .hotspot {
    opacity: 0.9;
    cursor: pointer !important;
}
.mapboxgl-popup {
    // promote popup, map comments on top of map buttons
    z-index: $z-index-map-popup;
}
.mapboxgl-popup-content {
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    padding: 20px;
    font-size: 13px;
    line-height: 1.4;
    box-shadow: 0px 0px 20px rgba(10, 25, 33, 0.7);
}
.mapboxgl-popup-hero {
    margin: -20px -20px 20px -20px;
    border-radius: 3px 3px 0 0;
    overflow: hidden;
}
.mapboxgl-popup-hero > * {
    max-width: 100%;
}
.mapboxgl-popup-title {
    font-weight: 300;
    margin-top: 0;
    font-size: 1.5em;
}
.mapboxgl-popup-desc ul {
    padding-left: 20px;
}
.mapboxgl-popup-desc > *:last-child {
    margin-bottom: 0;
}
.mapboxgl-popup-close-button {
    font-size: 2em;
    padding: 0 0.4em;
    background: rgba(255, 255, 255, 0.7);
    border-radius: 0 3px 0 3px;
}
.mapboxgl-popup-close-button:hover {
    background: rgba(255, 255, 255, 1);
}

.mapbox-sidebar-return {
    position: absolute;
    top: 10px;
    left: 10px;
    background: #fff;
    display: inline-block;
    padding: 6px 12px 8px;
    border-radius: 4px;
    z-index: 2;
    color: #333;
    font-weight: 300;
    font-size: 0.9em;
}
.mapbox-sidebar-return:hover {
    text-decoration: none;
    background: #f2f2f2;
    color: #333;
}
.map-top-left-controls {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 2;
}

/* =========================
   == HOTSPOTS ==
   ========================= */

.hotspot {
    position: absolute;
    display: table;
    width: 35px;
    height: 35px;
    width: calc(100vw * 0.035) !important;
    height: calc(100vw * 0.035) !important;
    text-align: center;
    background: #fff;
    font-size: 16px;
    font-size: 1.7vw;
    border-radius: 50%;
    border: 2px solid #fff;
    background: #00abe6;
    color: #fff;
    transform: translate(-50%, -50%);
}
.hotspot-sm {
    width: 23px;
    height: 23px;
    width: calc(100vw * 0.023) !important;
    height: calc(100vw * 0.023) !important;
    font-size: 11px;
    font-size: 1.1vw;
    border-width: 1px;
}
.hotspot-lg {
    width: 47px;
    height: 47px;
    width: calc(100vw * 0.047) !important;
    height: calc(100vw * 0.047) !important;
    font-size: 21px;
    font-size: 2.1vw;
    border-width: 3px;
}
.hotspot:hover,
.hotspot:focus {
    color: #fff;
    text-decoration: none;
    background: #0059a4;
}

.hotspot:hover:before {
    border-color: #0059a4;
}

/* Hotspot icon fixes */

.hotspot .fa {
    vertical-align: middle;
    display: table-cell;
}
.custom-icon svg {
    width: 75%;
    height: auto;
    vertical-align: middle;
}
.custom-icon path,
.custom-icon polygon,
.custom-icon-path {
    fill: #fff;
}

.hotspot-icon {
    max-width: 65%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

/* Hotspot tooltips */

.hotspot-tooltip {
    display: none;
    background: rgb(25, 43, 63);
    background: rgba(25, 43, 63, 0.7);
    border-radius: 4px;
    position: absolute;
    padding: 4px 8px;
    font-size: 11px;
    white-space: nowrap;
    left: 50%;
    top: 100%;
    color: #fff !important;
    line-height: 1.5;
    font-size: 1vw;
    transform-origin: center top;
    z-index: 3;
    transform: translate(-50%, 7px);
    font-family: 'Montserrat', sans-serif;
}
.show-hotspot-tooltip .hotspot-tooltip,
.hotspot:hover .hotspot-tooltip,
.hotspot:focus .hotspot-tooltip {
    display: block;
}
.hotspot:hover,
.hotspot:focus {
    z-index: 8;
}
/* Tooltip Position - Default is bottom-middle of circle */

.hotspot-tooltip:after {
    width: 0;
    height: 0;
    content: ' ';
    border: solid transparent;
    border-width: 6px;
    margin-left: -6px;
    border-color: rgba(25, 43, 63, 0);
    border-bottom-color: rgba(25, 43, 63, 0.7);
    position: absolute;
    bottom: 100%;
    left: 50%;
}
/* Tooltip Other Positions */

.hotspot-tooltip-t-m {
    top: auto;
    bottom: 100%;
    transform: translate(-50%, -7px);
    transform-origin: center bottom;
}
.hotspot-tooltip-t-m:after {
    border-bottom-color: rgba(25, 43, 63, 0);
    border-top-color: rgba(25, 43, 63, 0.7);
    top: 100%;
    bottom: auto;
}
.hotspot-tooltip-r-m {
    top: 50%;
    bottom: auto;
    left: 100%;
    transform: translate(7px, -50%);
    transform-origin: right center;
    text-align: left;
}
.hotspot-tooltip-r-m:after {
    border-bottom-color: rgba(25, 43, 63, 0);
    border-right-color: rgba(25, 43, 63, 0.7);
    bottom: auto;
    top: 50%;
    left: auto;
    right: 100%;
    margin-left: 0;
    margin-top: -6px;
}
.hotspot-tooltip-l-m {
    top: 50%;
    bottom: auto;
    left: auto;
    right: 100%;
    transform: translate(-7px, -50%);
    transform-origin: left center;
    text-align: right;
}
.hotspot-tooltip-l-m:after {
    border-bottom-color: rgba(25, 43, 63, 0);
    border-left-color: rgba(25, 43, 63, 0.7);
    bottom: auto;
    top: 50%;
    left: 100%;
    margin-left: 0;
    margin-top: -6px;
}

/* Hotspot hover sibling layers */
.hotspot-onhover {
    display: none;
}
.hotspot:hover + .hotspot-onhover {
    display: initial;
}
.hotspot-map-layer-inline {
    width: auto;
}

/* =========================
   =========================
   =========================
   =========================
   ===== MEDIA QUERIES =====
   =========================
   =========================
   =========================
   ========================= */

/* Small devices ONLY (767px and down) */
@media (max-width: 767px) {
    /* =========================
   == MAPS & SIDEBARS ==
   ========================= */

    .hotspot-map-type-toggles {
        display: block;
    }
    #mapTypeToggleList:checked ~ .hotspot-map-wrapper {
        padding: 25px;
    }
    #mapTypeToggleList:checked ~ .hotspot-map-wrapper > *:not(.hotspot),
    .hotspot-sm {
        display: none;
    }
    #mapTypeToggleList:checked ~ .hotspot-map-wrapper .hotspot {
        display: block;
        position: relative;
        right: auto !important;
        bottom: auto !important;
        left: auto !important;
        top: auto !important;
        padding: 9px 12px;
        background: transparent;
        border: 1px solid #333132;
        color: #333132;
        opacity: 0.7;
        margin-bottom: 10px;
        width: 100%;
        height: auto;
        border-radius: 3px;
        transform: translate(0, 0);
        font-size: 14px;
        text-align: left;
    }
    #mapTypeToggleList:checked ~ .hotspot-map-wrapper .hotspot.hidden-list {
        display: none;
    }
    #mapTypeToggleList:checked ~ .hotspot-map-wrapper .hotspot:hover,
    #mapTypeToggleList:checked ~ .hotspot-map-wrapper .hotspot:focus {
        background: #333132;
        color: #fff;
        opacity: 1;
    }
    #mapTypeToggleList:checked ~ .hotspot-map-wrapper .hotspot:before {
        display: none;
    }
    #mapTypeToggleList:checked ~ .hotspot-map-wrapper .hotspot .fa {
        display: inline-block;
    }
    #mapTypeToggleList:checked ~ .hotspot-map-wrapper .hotspot-tooltip {
        display: inline-block;
        position: relative;
        right: auto !important;
        bottom: auto !important;
        left: auto !important;
        top: auto !important;
        background: none;
        color: inherit !important;
        font-size: inherit;
        padding: 0 0 0 5px;
        margin: 0 !important;
        transform: translate(0, 0);
        white-space: normal;
    }
    #mapTypeToggleList:checked ~ .hotspot-map-wrapper .hotspot-tooltip:after,
    #mapTypeToggleList:checked ~ .hotspot-map-wrapper .hotspot-tooltip br {
        display: none;
    }
    #mapTypeToggleList:checked ~ .hotspot-map-wrapper .custom-icon svg {
        width: 19px;
        height: auto;
    }
    #mapTypeToggleList:checked ~ .hotspot-map-wrapper .custom-icon-path {
        fill: #333132;
    }
    #mapTypeToggleList:checked ~ .hotspot-map-wrapper .hotspot:hover .custom-icon-path {
        fill: #fff;
    }

    /* =========================
   == HOTSPOTS ==
   ========================= */

    .hotspot {
        border-width: 1px;
        width: 4.68vw !important;
        height: 4.68vw !important;
        font-size: 2.5vw;
    }

    .hotspot-tooltip {
        font-size: 10px;
    }
}

/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) {
    /* =========================
   == HOTSPOTS ==
   ========================= */

    /* Pulsate the hotspot */
    .hotspot:not(.hotspot-no-pulse):before {
        content: ' ';
        height: 110%;
        width: 110%;
        height: calc(100% + 10px);
        width: calc(100% + 10px);
        border: 4px solid #fff;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
        z-index: 1;
        position: absolute;
        left: -5px;
        top: -5px;
        pointer-events: none;
        opacity: 0;
        -webkit-animation: pulse 2s ease-out;
        -moz-animation: pulse 2s ease-out;
        animation: pulse 2s ease-out;
        -webkit-animation-iteration-count: infinite;
        -moz-animation-iteration-count: infinite;
        animation-iteration-count: infinite;
        -webkit-transform: scale(1);
    }
    @-moz-keyframes pulse {
        0% {
            -moz-transform: scale(0.7, 0.7);
        }
        33% {
            opacity: 0;
        }
        65% {
            opacity: 1;
        }
        100% {
            -moz-transform: scale(1.4, 1.4);
            opacity: 0;
        }
    }
    @-webkit-keyframes pulse {
        0% {
            -webkit-transform: scale(0.7, 0.7);
        }
        33% {
            opacity: 0;
        }
        65% {
            opacity: 1;
        }
        100% {
            -webkit-transform: scale(1.4, 1.4);
            opacity: 0;
        }
    }
    @keyframes pulse {
        0% {
            transform: scale(0.7, 0.7);
        }
        33% {
            opacity: 0;
        }
        65% {
            opacity: 1;
        }
        100% {
            transform: scale(1.4, 1.4);
            opacity: 0;
        }
    }

    .visible-tooltip .hotspot-tooltip {
        display: block;
    }
}

/* Medium devices (desktops, 992px and up) */
@media (min-width: 992px) {
} /* END @media (min-width: 992px ) */

/* Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
    /* =========================
   == MAPS & SIDEBARS ==
   ========================= */

    .map-with-sidebar-left .map-sidebar,
    .map-with-sidebar-right .map-sidebar {
        width: 25%;
        float: left;
        padding-bottom: 100px;
        transition: all;
    }
    .map-with-sidebar-right .map-sidebar {
        float: right;
    }
    .map-with-sidebar-toggle.map-with-sidebar-left .sidebar-toggle,
    .map-with-sidebar-toggle.map-with-sidebar-right .sidebar-toggle {
        display: block;
    }
    .map-with-sidebar-toggle.map-with-sidebar-left .map-sidebar {
        margin-left: -25%;
    }
    .map-with-sidebar-toggle.map-with-sidebar-right .map-sidebar {
        margin-right: -25%;
    }
    .map-with-sidebar-toggle #mapSidebarToggle:checked ~ .map-sidebar {
        margin-left: 0;
        margin-right: 0;
    }

    .map-with-sidebar-left .page-title,
    .map-with-sidebar-right .page-title,
    .map-with-sidebar-left .map-sidebar-panel-body,
    .map-with-sidebar-right .map-sidebar-panel-body,
    .map-with-sidebar-left .map-sidebar-panel-footer,
    .map-with-sidebar-right .map-sidebar-panel-footer {
        max-width: 480px;
    }

    .map-with-sidebar-top .page-title,
    .map-with-sidebar-bottom .page-title,
    .map-with-sidebar-top .map-sidebar-panel-body,
    .map-with-sidebar-bottom .map-sidebar-panel-body,
    .map-with-sidebar-top .map-sidebar-panel-footer,
    .map-with-sidebar-bottom .map-sidebar-panel-footer {
        max-width: 1170px;
        margin-left: auto;
        margin-right: auto;
    }
    .map-with-sidebar-top .page-title,
    .map-with-sidebar-bottom .page-title {
        max-width: 1120px;
    }
    .map-with-sidebar-top .map-sidebar-column,
    .map-with-sidebar-bottom .map-sidebar-column {
        width: 48%;
        float: left;
        margin-right: 2%;
    }
    .map-with-sidebar-top .map-sidebar-column:last-child,
    .map-with-sidebar-bottom .map-sidebar-column:last-child {
        margin-right: 0;
        float: right;
    }
    .map-with-sidebar-top .map-sidebar-panel-footer .note .fa-angle-left {
        transform: rotate(-90deg);
    }
    .map-with-sidebar-bottom .map-sidebar-panel-footer .note .fa-angle-left {
        transform: rotate(90deg);
    }

    .map-with-sidebar-left .hotspot-map,
    .map-with-sidebar-right .hotspot-map {
        overflow: auto;
        overflow-x: hidden;
    }

    .hotspot-map-key-btn {
        position: fixed;
        bottom: 10px;
        left: 10px;
        background: #fff;
        padding: 5px 12px;
        color: #090909;
        z-index: 9;
    }
    .hotspot-map-key-btn:hover {
        background: #eee;
    }
    .hotspot-map-zoom-btn {
        bottom: auto;
        top: 10px;
        position: absolute;
    }

    /* =========================
   == HOTSPOTS ==
   ========================= */

    .hotspot {
        width: calc(100vw * 0.02) !important;
        height: calc(100vw * 0.02) !important;
        font-size: 1vw;
    }
    .hotspot-sm {
        width: 15px;
        height: 15px;
        width: calc(100vw * 0.0134) !important;
        height: calc(100vw * 0.0134) !important;
        font-size: 7px;
        font-size: 0.7vw;
    }
    .hotspot-lg {
        width: 25px;
        height: 25px;
        width: calc(100vw * 0.025) !important;
        height: calc(100vw * 0.025) !important;
        font-size: 15px;
        font-size: 0.15vw;
    }

    .hotspot-tooltip {
        font-size: 0.7vw;
    }
}

/* Small AND Medium devices (tablets up to large desktop screens, 768px up to 1199px) */
@media (min-width: 768px) and (max-width: 1199px) {
    /* =========================
   == MAPS & SIDEBARS ==
   ========================= */

    .map-sidebar-column {
        width: 48%;
        float: left;
        margin-right: 2%;
    }
    .map-sidebar-column:last-child {
        margin-right: 0;
        float: right;
    }
}
