/* ===========
  Animation
 =============*/
/* Bounce 1 */
@-webkit-keyframes cd-bounce-1 {
    0% {
        opacity: 0;
        -webkit-transform: scale(0.5);
    }
    60% {
        opacity: 1;
        -webkit-transform: scale(1.2);
    }
    100% {
        -webkit-transform: scale(1);
    }
}
@-moz-keyframes cd-bounce-1 {
    0% {
        opacity: 0;
        -moz-transform: scale(0.5);
    }
    60% {
        opacity: 1;
        -moz-transform: scale(1.2);
    }
    100% {
        -moz-transform: scale(1);
    }
}
@-o-keyframes cd-bounce-1 {
    0% {
        opacity: 0;
        -o-transform: scale(0.5);
    }
    60% {
        opacity: 1;
        -o-transform: scale(1.2);
    }
    100% {
        -o-transform: scale(1);
    }
}
@keyframes cd-bounce-1 {
    0% {
        opacity: 0;
        -webkit-transform: scale(0.5);
        -moz-transform: scale(0.5);
        -ms-transform: scale(0.5);
        -o-transform: scale(0.5);
        transform: scale(0.5);
    }
    60% {
        opacity: 1;
        -webkit-transform: scale(1.2);
        -moz-transform: scale(1.2);
        -ms-transform: scale(1.2);
        -o-transform: scale(1.2);
        transform: scale(1.2);
    }
    100% {
        opacity: 1;
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1);
    }
}
/* Bounce 2 */
@-webkit-keyframes cd-bounce-2 {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-100px);
    }
    60% {
        opacity: 1;
        -webkit-transform: translateX(20px);
    }
    100% {
        -webkit-transform: translateX(0);
    }
}
@-moz-keyframes cd-bounce-2 {
    0% {
        opacity: 0;
        -moz-transform: translateX(-100px);
    }
    60% {
        opacity: 1;
        -moz-transform: translateX(20px);
    }
    100% {
        -moz-transform: translateX(0);
    }
}
@-o-keyframes cd-bounce-2 {
    0% {
        opacity: 0;
        -o-transform: translateX(-100px);
    }
    60% {
        opacity: 1;
        -o-transform: translateX(20px);
    }
    100% {
        opacity: 1;
        -o-transform: translateX(0);
    }
}
@keyframes cd-bounce-2 {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-100px);
        -moz-transform: translateX(-100px);
        -ms-transform: translateX(-100px);
        -o-transform: translateX(-100px);
        transform: translateX(-100px);
    }
    60% {
        opacity: 1;
        -webkit-transform: translateX(20px);
        -moz-transform: translateX(20px);
        -ms-transform: translateX(20px);
        -o-transform: translateX(20px);
        transform: translateX(20px);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
    }
}
/* Dropdown */
@-webkit-keyframes dropdownOpen {
    0% {
        opacity: 0;
        -webkit-transform: scale(0);
    }
    100% {
        -webkit-transform: scale(1);
    }
}
@-moz-keyframes dropdownOpen {
    0% {
        opacity: 0;
        -moz-transform: scale(0);
    }
    100% {
        -moz-transform: scale(1);
    }
}
@-o-keyframes dropdownOpen {
    0% {
        opacity: 0;
        -o-transform: scale(0);
    }
    100% {
        -o-transform: scale(1);
    }
}
@keyframes dropdownOpen {
    0% {
        opacity: 0;
        -webkit-transform: scale(0);
        -moz-transform: scale(0);
        -ms-transform: scale(0);
        -o-transform: scale(0);
        transform: scale(0);
    }
    100% {
        opacity: 1;
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1);
    }
}
/* Progressbar Animated */
@-webkit-keyframes animationProgress {
    from {
        width: 0;
    }
}
@keyframes animationProgress {
    from {
        width: 0;
    }
}
/* Portlets loader */
@-webkit-keyframes loaderAnimate {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(220deg);
    }
}
@-moz-keyframes loaderAnimate {
    0% {
        -moz-transform: rotate(0deg);
    }
    100% {
        -moz-transform: rotate(220deg);
    }
}
@-o-keyframes loaderAnimate {
    0% {
        -o-transform: rotate(0deg);
    }
    100% {
        -o-transform: rotate(220deg);
    }
}
@keyframes loaderAnimate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(220deg);
    }
}
@-webkit-keyframes loaderAnimate2 {
    0% {
        box-shadow: inset #555 0 0 0 8px;
        -webkit-transform: rotate(-140deg);
    }
    50% {
        box-shadow: inset #555 0 0 0 2px;
    }
    100% {
        box-shadow: inset #555 0 0 0 8px;
        -webkit-transform: rotate(140deg);
    }
}
@-moz-keyframes loaderAnimate2 {
    0% {
        box-shadow: inset #555 0 0 0 8px;
        -moz-transform: rotate(-140deg);
    }
    50% {
        box-shadow: inset #555 0 0 0 2px;
    }
    100% {
        box-shadow: inset #555 0 0 0 8px;
        -moz-transform: rotate(140deg);
    }
}
@-o-keyframes loaderAnimate2 {
    0% {
        box-shadow: inset #555 0 0 0 8px;
        -o-transform: rotate(-140deg);
    }
    50% {
        box-shadow: inset #555 0 0 0 2px;
    }
    100% {
        box-shadow: inset #555 0 0 0 8px;
        -o-transform: rotate(140deg);
    }
}
@keyframes loaderAnimate2 {
    0% {
        box-shadow: inset #555 0 0 0 8px;
        -webkit-transform: rotate(-140deg);
        -moz-transform: rotate(-140deg);
        -ms-transform: rotate(-140deg);
        transform: rotate(-140deg);
    }
    50% {
        box-shadow: inset #555 0 0 0 2px;
    }
    100% {
        box-shadow: inset #555 0 0 0 8px;
        -webkit-transform: rotate(140deg);
        -moz-transform: rotate(140deg);
        -ms-transform: rotate(140deg);
        transform: rotate(140deg);
    }
}
@keyframes loaderAnimate2 {
    0% {
        box-shadow: inset #999 0 0 0 17px;
        transform: rotate(-140deg);
    }
    50% {
        box-shadow: inset #999 0 0 0 2px;
    }
    100% {
        box-shadow: inset #999 0 0 0 17px;
        transform: rotate(140deg);
    }
}
@keyframes bg-flash-success {
    0% {
        background-color: #23b481;
        opacity: 0.4;
    }
    100% {
        background-color: transparent;
    }
}
