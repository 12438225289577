/* ===========
   Carousel
 =============*/
.carousel-control {
    width: 10%;
}
.carousel-control span {
    position: absolute;
    top: 50%;
    /* pushes the icon in the middle of the height */
    z-index: 5;
    display: inline-block;
    font-size: 30px;
}
.carousel-indicators li {
    border: 2px solid #ffffff;
}
