.vue-query-builder {
    .vqb-group.depth-1 .vqb-rule,
    .vqb-group.depth-2,
    .vqb-group.depth-3 {
        border-left: 4px solid $brand-primary !important;
    }

    .vqb-rule.card {
        label {
            font-size: 1.4rem;
            font-weight: 600;
            margin-bottom: 0;
            margin-right: 2rem !important;
        }

        .close {
            margin-left: 1rem !important;
        }

        select.form-control,
        input.form-control {
            flex-grow: 1;
        }

        select.form-control + input.form-control {
            flex-grow: 4;
        }
    }
}

.form-group.segment-query.has-error .vue-query-builder > .card {
    border-color: #ef5350;
    background: #fff4f2;
}

.form-group.segment-query.has-error,
.form-group.segment-query.has-success {
    .form-control {
        border-color: $input-border;
    }
}
