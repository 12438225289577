$color-active: #3bafda;
$color-inactive: #c8c8c8;
$color-hover: #7aa3cc;

label.checkbox-toggle {
    background-color: none;
    border: 0px solid #555555;
    border-radius: 3px;
    color: #555555;
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    font-weight: normal;
    line-height: 2em;
    margin-bottom: 0;
    padding: 0;
    text-align: left;
    white-space: normal;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;

    &.active {
        color: $color-active;
    }

    &:active,
    &.active {
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    &:hover {
        color: $color-hover;

        i.fa {
            color: $color-hover;
        }
    }

    span {
        font-size: 1.1em;
    }

    i.fa {
        display: inline-block;
        font-size: 25px;
        vertical-align: middle;
    }

    i.fa.fa-square {
        color: $color-inactive;
        padding-right: 4px;
    }

    input[type='checkbox'] {
        & ~ i.fa.fa-square {
            color: $color-inactive;
            display: inline-block;
        }
        & ~ i.fa-check-square {
            display: none;
        }

        &:checked {
            & ~ i.fa.fa-square {
                display: none;
            }

            & ~ i.fa.fa-check-square {
                display: inline-block;
                color: $color-active;
            }
        }
    }

    @media (max-width: 767px) {
        i.fa {
            font-size: 21px;
        }

        span {
            font-size: 0.9em;
        }
    }
}
