/*
Template Name: Minton Dashboard
Author: SpatialMedia
File: CA Styles
*/

/* General */
@use "sass:map";
@import url('https://fonts.googleapis.com/css?family=Barlow:300,300i,600');

html,
body {
}
body {
    font-family: 'Barlow', sans-serif;
    font-weight: 300;
    padding-top: 120px;
}
body.modal-open {
    //fix for bs3 adding padding to body on modal open
    padding-right: 0 !important;
}
.iframe-clean {
    border: 0 none;
    outline: none;
}

.text-sm {
    font-size: 85%;
}

.container-restrict {
    max-width: 1200px;
    margin: 0 auto;
}

.single-line {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.table-narrow-last td:last-child {
    width: 1%;
    white-space: nowrap;
}
.table-valign-middle tbody tr td {
    vertical-align: middle;
}
table.dataTable {
    min-width: 100%;

    th select.dt-filter {
        max-width: 200px;
    }

    th select.dt-filter.active {
        border: 2px solid #3bafda;
    }

    .datatable-error {
        text-align: center;
    }
}
table.dataTable tr th,
table.dataTable tr td {
    min-width: 100px;
}

.checkbox-list > label {
    display: block;
    padding-top: 2px;
    padding-bottom: 2px;
    font-weight: inherit;
}

.dark {
    background: #000000;
    color: #fff;
}

.label-success {
    background-color: #5cb85c;
}
.text-success {
    color: #5cb85c;
}

.blockquote {
    background: #fff;
    border-left: 5px solid #eee;
    padding: 15px;
}
.blockquote > *:last-child {
    margin-bottom: 0 !important;
}

.touch .hidden-touch {
    display: none !important;
}
.touch .visible-touch {
    display: block !important;
}
.touch .visible-inline-touch {
    display: inline !important;
}
.touch .visible-inline-block-touch {
    display: inline-block !important;
}

.alert-broadcast {
    position: fixed;
    z-index: 1050;
    background-color: rgba(0, 177, 89, 0.9) !important;
    border: 1px solid rgba(0, 177, 89, 1);
    text-align: center;
    left: 50%;
    color: #dff1ee;
    transform: translate(-50%, 0);
    top: 137px;
}
.alert-broadcast .alert-link {
    color: #fff;
}
.alert-broadcast .alert-link:hover {
    color: #fff;
}
.alert-broadcast .close {
    opacity: 1;
    text-shadow: none;
}
.alert-message {
    h1 {
        font-size: 1.4rem;
        line-height: normal;
        margin: 0 0 0.5rem 0;
    }

    a:active,
    a:hover {
        text-decoration: underline;
    }

    &.alert-danger {
        h1 {
            color: map-get($ca-branding, 'error');
        }

        a {
            color: map-get($ca-branding, 'error');
        }
    }

    &.alert-warning {
        h1 {
            color: map-get($ca-branding, 'warning');
        }

        a {
            color: map-get($ca-branding, 'warning');
        }
    }

    &.alert-success {
        h1 {
            color: map-get($ca-branding, 'success');
        }

        a {
            color: map-get($ca-branding, 'success');
        }
    }

    &.alert-info {
        h1 {
            color: map-get($ca-branding, 'info');
        }

        a {
            color: map-get($ca-branding, 'info');
        }
    }
}
th,
td {
    /* These are technically the same, but use both */
    overflow-wrap: break-word;
    word-wrap: break-word;

    -ms-word-break: break-all;
    /* Instead use this non-standard one: */
    word-break: break-word;
}

/* Buttons */

.btn small {
    // btn sub text
    font-size: 70%;
    margin-left: 0.25rem;
}

.btn-youtube {
    font-size: 24px;
    color: #c4302b;
    line-height: 24px;
    padding: 0 !important;
}

/* Forms */

.form-group {
    margin-bottom: 10px;
}

label {
    font-weight: 300;
    font-size: 90%;
}
.form-control,
.input-group-addon {
    font-size: 14px;
    height: 36px;
}
.form-control::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #bcbcbc;
}
.form-control::-moz-placeholder {
    /* Firefox 19+ */
    color: #bcbcbc;
}
.form-control:-ms-input-placeholder {
    /* IE 10+ */
    color: #bcbcbc;
}
.form-control::-moz-placeholder {
    /* Firefox 18- */
    color: #bcbcbc;
}

.form-control-lg {
    height: 46px;
    font-size: 16px;
}

.input-group > * {
    border: 0;
}
.input-group > *:first-child {
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
}
.input-group > *:last-child {
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
}

.form-group.has-error > label:after {
    content: 'Missing valid input';
    color: #ef5350;
    border-radius: 2em;
    font-style: italic;
    padding-left: 4px;
}
.has-error > input {
    background: #fff4f2;
}

.page-search {
    margin-bottom: 0.8em;
    padding: 20px 10px 10px;
    background: #f3f6f8;
    border-radius: 2px;
}
.m-b-30 + .page-search {
    margin-top: -1em;
}
.page-search .input-group {
    display: flex;
    margin-bottom: 10px;
}
.page-search #search-current-location {
    padding-top: 7px;
    padding-bottom: 7px;
}

/* Fancy Checkboxes and Radio Buttons */

.fancy-radios {
    margin-bottom: -6px;
}
.fancy-radios > input[type='radio'],
.fancy-radios > input[type='checkbox'] {
    display: none;
}
.fancy-radio {
    display: inline-block;
    font-weight: 300;
    height: 36px;
    padding: 6px 12px 6px 42px;
    font-size: 13px;
    line-height: 1.728571;
    background-color: #fafafa;
    border-radius: 2px;
    border: 1px solid #eeeeee;
    cursor: pointer;
    position: relative;
    margin: 0 3px 6px 0;
}
.fancy-radio:before {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 36px;
    text-align: center;
    font: normal normal normal 14px/1 'Font Awesome 5 Pro';
    content: '\f0c8';
    line-height: 34px;
    background: #eeeeee;
    color: #ccc;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
}
.fancy-radio:hover {
    background: #eeeeee;
    border-color: #ddd;
}
.fancy-radio:hover:before {
    background: #ddd;
    color: #fff;
}
.fancy-radios > input[type='radio']:checked + .fancy-radio:before,
.fancy-radios > input[type='checkbox']:checked + .fancy-radio:before {
    content: '\f00c';
    background: $brand-primary;
    color: #fff;
}

.fancy-radios.radio {
    label {
        padding-left: 42px;
    }
}
.fancy-radios.radio-inline {
    margin-top: 0;
    padding-left: 0;
}

input:disabled + .fancy-radio {
    opacity: 0.5;
    pointer-events: none;
    cursor: not-allowed;
}

/* List Groups */

.list-group-item.success {
    border-left: 4px solid #5cb85c;
}
.list-group-item.default {
    border-left: 4px solid #777;
}
.list-group-item.warning {
    border-left: 4px solid #ffaa00;
}
.list-group-item.danger {
    border-left: 4px solid #ef5350;
}
.list-group-item.primary {
    border-left: 4px solid #3bafda;
}

.list-group-item-empty {
    text-align: center;
    padding: 100px 20px !important;
}

/* Button Groups */

.btn-group-justified .btn:not(:hover) {
    border-right: 1px solid #fff;
}
.btn-group-justified .btn:hover {
}
.btn-group-justified .btn:last-child {
    border-right: 0 none;
}

/* Lists */

.blockquote-list {
    margin: 0;
    padding: 0;
    list-style: none;
}
.blockquote-list > li {
    display: block;
    background: #fafafa;
    border: 1px solid #f6f7f9;
    margin: 0 0 10px;
    padding: 10px;
    border-left: 5px solid #eee;
}
.user-tabs-list {
    margin: 0;
    padding: 0;
    list-style: none;
}
.user-tabs-list > li {
    display: inline-block;
    background: #fafafa;
    border: 1px solid #f6f7f9;
    padding: 2px;
    height: 40px;
    line-height: 40px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 80%;
    border-radius: 20px;

    &.is-success {
        animation-name: bg-flash-success;
        animation-duration: 1000ms;
        animation-iteration-count: 1;
        animation-timing-function: ease-in-out;
    }
}
.user-tab-avatar {
    border-radius: 50%;
    display: inline-block;
    height: 34px;
    width: auto;
    vertical-align: top;
}
.user-tab-name {
    display: inline-block;
    padding: 0 10px 0 7px;
    vertical-align: top;
    line-height: 32px;
}

/* Tabs */

.nav.nav-tabs .tab a {
    font-size: 80%;
    line-height: 50px;
    text-transform: uppercase;
}

/* Header & Sidebar */

#topnav .topbar-main {
    background: #091c2a;
}

#topnav .topbar-main .collapse {
    display: block;
}

.navbar-default .navbar-collapse {
    margin-left: -15px;
    margin-right: -15px;
}
#topnav .topbar-main .logo {
    margin: 12px 10px 0;
    line-height: normal;
}
#topnav .topbar-main .navbar-brand {
    height: 60px;
    padding: 12px 15px 12px 15px;
    background-color: map.get($ca-branding, 'primary');
}

.topbar-main .navbar-nav .img-circle {
    max-width: 36px;
    max-height: 36px;
    overflow: hidden;
}

#topnav .topbar-main .nav > li > a {
    text-transform: uppercase;
    font-size: 12px;
}
#topnav .topbar-main .nav > li:hover {
    background-color: rgba(255, 255, 255, 0.1) !important;
}
#topnav .topbar-main .nav > li:hover > .dropdown-menu {
    display: block;
}

.navbar .dropdown.open {
    background: #818f98;
}

.client-detail {
    position: relative;
    bottom: auto;
    left: auto;
    right: auto;
    border-top: 0 none;
    padding: 15px 20px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
}

.breadcrumb-toggle {
    display: none;
}
.breadcrumb-toggle-label {
    cursor: pointer;
    display: none;
}
.page-title-box .breadcrumb {
    margin-bottom: 0;
    padding: 0;
}
.breadcrumb .active {
    font-weight: 700;
}
.breadcrumb .active:before {
    font-weight: 300;
}
.breadcrumb li {
    vertical-align: middle;
    padding: 7px 0;
}
.breadcrumb .breadcrumb-project {
    padding: 0;
}
.breadcrumb > li + li:before {
    content: '>';
}

.dropdown-menu ul {
    list-style: none;
    margin: 0;
    padding: 0;
}
.dropdown-menu li a {
    padding: 8px 12px;
    color: #626d75;
    border-left: 1px solid #eee;
    font-weight: 300;
    display: block;
    font-size: 94%;
    cursor: pointer;
}
.dropdown-menu li.disabled a {
    pointer-events: none;
}
.dropdown-menu > li > a > i {
    color: #3bafdb;
    padding-right: 5px;
}
.dropdown-menu li a:focus,
.dropdown-menu li a:hover {
    background: #f5f5f5;
}
.dropdown-menu ul ul a {
    padding-left: 50px;
    font-size: 88%;
}
.dropdown-menu ul ul ul a {
    padding-left: 70px;
    font-size: 82%;
}
.dropdown-menu li.active > a {
    font-weight: 700;
    background: #3bafda;
    color: #fff;
}
.dropdown-menu li.disabled {
    opacity: 0.6;
}

.navigation-menu > li > a {
    font-size: 85%;
    text-transform: uppercase;
    color: #fff;
}
#topnav .navigation-menu > li > a:hover {
    background-color: #3d6173 !important;
}
#topnav .navigation-menu > li .submenu > li.has-submenu > a:after {
    display: none;
}

#topnav .topbar-main .nav a.active {
    background: #fff !important;
    color: #0a1b29 !important;
}
#topnav .topbar-main .nav .dropdown-menu a.active {
    background: #d7dee4 !important;
}

/* Projects */

.page-parent-divider {
    padding: 5px 0;
    color: #999;
    opacity: 0.5;
}

.project-quicklaunch {
    margin-bottom: 1em;
}
.project-quicklaunch-title {
    font-size: 16px;
    text-transform: uppercase;
    border-bottom: 1px solid #eaeaea;
    padding-bottom: 0.5em;
}
.project-quicklaunch-menu {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
}
.project-quicklaunch-item {
    margin-bottom: 1em;
    min-height: 220px;
}
.project-quicklaunch-link {
    display: block;
    padding: 15px 25px;
    text-align: center;
    background: #fafafa;
    border-radius: 5px;
    transition: all 0.3s;
    height: 100%;
}
.project-quicklaunch-link:hover {
    box-shadow: 0px 5px 25px rgba(10, 22, 29, 0.25);
    background: #fff;
}
.project-quicklaunch-icon {
    font-size: 70px;
    color: #3bafda;
    transition: all 0.3s;
}
.project-quicklaunch-link:hover .project-quicklaunch-icon {
    color: #6cc6d6;
}
.project-quicklaunch-subtitle {
    text-transform: uppercase;
}
.project-quicklaunch-desc {
    color: #959a9e;
    font-size: 80%;
}

/* Dashboard */

.dashboard-cards .card-box,
.dashboard-cards div:nth-child(8n + 1) .card-box,
.dashboard-cards div:nth-child(8n + 2) .card-box {
    background: #3bafda !important;
    color: #fff !important;
}
.dashboard-cards div:nth-child(8n + 3) .card-box,
.dashboard-cards div:nth-child(8n + 4) .card-box {
    background: #6cc6d6 !important;
}
.dashboard-cards div:nth-child(8n + 5) .card-box,
.dashboard-cards div:nth-child(8n + 6) .card-box {
    background: #7266ba !important;
}
.dashboard-cards div:nth-child(8n + 7) .card-box,
.dashboard-cards div:nth-child(8n + 8) .card-box {
    background: #00b19d !important;
}
.widget-icon i {
    line-height: 0.9;
}
.wid-title {
    text-transform: uppercase;
    font-size: 11px;
    margin: 0;
}
.wid-value {
    color: #fff;
    margin: 0;
    font-size: 24px;
    line-height: 1.2;
}

.panel-padded {
    padding: 0 20px 20px;
}

/* Content */

.page-title-box {
    margin: 0;
    padding: 13px 20px;
    position: fixed;
    top: 60px;
    left: 0;
    width: 100%;
    z-index: 1000;
}

.content-page {
    margin: 15px 0 0;
}
.content-page > .content {
    margin: 0;
    padding: 0;
}
.content-with-sidebar .content-main {
    overflow: auto;
}
.content-sidebar {
    float: right;
    width: 300px;
    margin-left: 15px;
}

.edit-suite {
    position: relative;
    min-height: 800px;
    min-height: 90vh;
    min-height: calc(100vh - #{$header-height});
    margin: -15px -25px;
}

.side-bar.is-saving,
.reply.is-saving {
    position: relative;

    &:after {
        display: block;
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: rgba(white, 0.4);
    }
}
.side-bar.edit-suite-sidebar {
    right: 0;
    top: $header-height;
    position: fixed;
    height: 100%;
    height: calc(100% - #{$header-height});
    background: #fff;
    width: $sidebar-width;
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.19);
}
.side-bar.edit-suite-sidebar-offscreen {
    right: -#{$sidebar-width};
}
.side-bar.edit-suite-sidebar-offscreen.active {
    right: 0;
}
.side-bar.is-open + .edit-suite-content {
    position: fixed;
    width: calc(100% - #{$sidebar-width});
}
.edit-suite-sidebar .header-title,
.panel-body .header-title {
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    font-size: 13px;
    padding-bottom: 5px;
    margin: 1.6rem 0 1rem 0;
    border-bottom: 1px solid map-get($ca-branding, 'lightest-gray');
}
.edit-suite-sidebar .form-group {
    margin-bottom: 7px;
}
.edit-suite-sidebar .fancy-radio {
    font-size: 12px;
    height: 32px;
    line-height: 18px;
    width: 100%;
}

.edit-suite-panel-header h4 {
    font-size: 80%;
    text-transform: uppercase;
    margin: 0;
    line-height: 50px;
    padding: 0 15px;
}
.edit-suite-panel-header .pull-right {
    display: inline-block;
    margin: 0 -15px 0 0;
    font-size: 125%;
}
.edit-suite-panel-header .pull-right a {
    display: inline-block;
    padding: 0 15px;
}
.edit-suite-panel-body {
    padding: 15px;
}

.edit-suite-content {
    position: fixed;
    top: $header-height;
    left: 0;
    width: calc(100vw - #{$sidebar-width});
    height: calc(100vh - #{$header-height});
}

.edit-suite-content .full-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}
.edit-suite-content.active {
    background: #091c2a;
}

/* Review */

.review-comment {
    background: #fafafa;
    margin-top: 1rem;
    border: 1px solid #eee;
    border-radius: 3px;
    overflow: hidden;
    //font-size: 90%;
    //opacity: 0.9;

    hr {
        margin: 1rem 0;
    }

    &.is-active {
        box-shadow: 0 0 0 2px map-get($ca-branding, 'primary');
    }
}
.review-comment-actions {
    padding: 0 1rem 2rem 1rem;
}
.review-comment-complete {
    //opacity: 0.6;
}
.review-comment-recall-container .review-comment {
    opacity: 1;
}
.review-comment:hover {
    opacity: 1;
    cursor: pointer;
    background: #f6f7f9;
}
.review-comment-main {
    clear: both;
    overflow: hidden;
    display: flex;
}
.review-comment-aside {
    width: 33%;
    text-align: center;
}
.review-comment-thumb {
    position: relative;
}
.review-comment-thumb-img {
    display: block;
    width: 100%;
}
.review-comment-thumb-markup {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.review-image-marker.review-comment-thumb-marker {
    width: 12px;
    height: 12px;
    display: block;
}
.review-image-marker.review-comment-thumb-marker:before {
    -webkit-animation: unset;
    -moz-animation: unset;
    animation: unset;
}
.review-comment-meta {
    padding: 10px;
}
.review-comment-meta > span {
    display: block;
}
.review-comment-author {
    font-weight: 600;
    color: #4c5667;
    font-size: 90%;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
}
.review-comment-date {
    font-size: 80%;
    color: #98a6ad;
}
.review-comment-content {
    width: 67%;
    padding: 10px;
    border-left: 1px solid #eee;
}
.review-comment-content > *:last-child {
    margin-bottom: 0;
}

#reviewAddCommentComment {
    height: 150px;
}
.review-history-list > li {
    font-size: 80%;
    padding: 8px;
    margin-bottom: 5px;
}
.review-history-date {
    display: block;
    font-size: 80%;
    opacity: 0.7;
}
.review-image-wrapper {
    max-width: 96%;
    max-width: calc(100% - 90px);
    margin: 40px auto;
    position: relative;
}
.review-document-wrapper {
    width: 100%;
    margin: 0;
    overflow-y: scroll;
}
.review-image-wrapper:hover {
    cursor: crosshair;
}
.review-image-wrapper > .review-image {
    width: 100%;
    height: auto;
}
.edit-suite-content.active .review-image {
    pointer-events: none;
}
.review-image-prompt {
    position: absolute;
    top: 50%;
    left: 50%;
    display: inline-block;
    background: rgba(9, 24, 38, 0.7);
    color: #fff;
    opacity: 0.75;
    padding: 10px 20px;
    font-style: italic;
    transform: translate(-50%, -50%);
}
.edit-suite-content.active .review-image-prompt,
.review-image-wrapper:hover .review-image-prompt {
    display: none;
}

.review-image-markup-recall {
    display: none;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}
.review-image-wrapper-recall:hover {
    cursor: default;
}

.review-image-wrapper-recall .review-image-markup-recall {
    display: block;
}

.review-image-marker {
    display: none;
    position: absolute;
    width: 25px;
    height: 25px;
    background: rgba(59, 175, 218, 0.85);
    border-radius: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid #fff;
    z-index: 2;
}
.review-image-marker:before {
    content: ' ';
    height: 110%;
    width: 110%;
    height: calc(100% + 10px);
    width: calc(100% + 10px);
    border: 4px solid #fff;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    z-index: 1;
    position: absolute;
    left: -5px;
    top: -5px;
    pointer-events: none;
    opacity: 0;
    -webkit-animation: pulse 2s ease-out;
    -moz-animation: pulse 2s ease-out;
    animation: pulse 2s ease-out;
    -webkit-animation-iteration-count: infinite;
    -moz-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-transform: scale(1);
}
@-moz-keyframes pulse {
    0% {
        -moz-transform: scale(0.7, 0.7);
    }
    33% {
        opacity: 0;
    }
    65% {
        opacity: 1;
    }
    100% {
        -moz-transform: scale(1.4, 1.4);
        opacity: 0;
    }
}
@-webkit-keyframes pulse {
    0% {
        -webkit-transform: scale(0.7, 0.7);
    }
    33% {
        opacity: 0;
    }
    65% {
        opacity: 1;
    }
    100% {
        -webkit-transform: scale(1.4, 1.4);
        opacity: 0;
    }
}
@keyframes pulse {
    0% {
        transform: scale(0.7, 0.7);
    }
    33% {
        opacity: 0;
    }
    65% {
        opacity: 1;
    }
    100% {
        transform: scale(1.4, 1.4);
        opacity: 0;
    }
}
.edit-suite-content.active .review-image-marker {
    display: block;
}
.review-image-wrapper-markup .review-image-marker {
    cursor: move;
}

.review-image-markup {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;
}
.edit-suite-content.active .review-image-markup {
    display: block;
}
.review-image-markup-tools {
    list-style: none;
    display: none;
    position: absolute;
    top: 1rem;
    right: 1rem;
    padding: 0;
}
.review-image-wrapper-markup .review-image-markup-tools {
    display: block;
}
.review-image-markup-tools > li {
    display: inline-block;
    vertical-align: top;
}
.review-image-markup-tool {
    display: block;
    min-width: 28px;
    height: 28px;
    line-height: 26px;
    border-radius: 3px;
    padding: 0 13px;
    position: relative;
    border-color: transparent;

    //@at-root: #091c2a;

    &-size,
    &-undo,
    &-redo,
    &-clear {
        @extend .btn, .btn-default;
    }

    &-size,
    &-undo,
    &-redo,
    &-clear,
    &-colour,
    &-size:after,
    &-colour:after {
        box-shadow: 1px 1px 5px 0px rgba(black, 0.2);
    }
}
.review-image-markup-tool-size {
    padding: 0;
}
.review-image-markup-tool-divider {
    margin-left: 2px;
}
.review-image-markup-tool.active {
    border-color: #fff;
}
.review-image-markup-tool.active:after {
    content: ' ';
    position: absolute;
    width: 6px;
    height: 6px;
    background: #fff;
    top: 100%;
    left: 50%;
    transform: translate(-50%, 3px);
    border-radius: 50%;
}
.review-image-markup-tool .fa-sm {
    font-size: 60%;
    vertical-align: 15%;
}
.review-image-markup-tool .fa-lg {
    font-size: 150%;
    line-height: 1;
}

/* Layer Edit */

.layer-list {
    list-style: none;
    margin: 0;
    padding: 0;
}
.layer-list-item {
    margin: 0 0 15px;
    padding: 5px;
    clear: both;
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 50px;
    background: #fafafa;
    overflow: hidden;
}
.layer-preview-thumb {
    position: absolute;
    width: 50px;
    height: 100%;
    left: 0;
    top: 0;
    background: -webkit-linear-gradient(#0a1e2b 0%, #0a345a 100%);
}
.layer-meta {
    padding: 0 10px 0 55px;
}
.layer-meta-title {
    line-height: 13px;
    margin-top: 5px;
}
.layer-meta-other {
    list-style: none;
    padding: 0;
    margin: 0;
    line-height: 20px;
    height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
}
.layer-meta-other li {
    display: inline-block;
    font-size: 10px;
    opacity: 0.6;
    margin-right: 7px;
}
.layer-actions {
    display: none;
    position: absolute;
    right: 0;
    top: 0;
    height: 50px;
    background: #fafafa;
}
.layer-actions > .btn {
    display: inline-block;
    line-height: 48px;
    padding-top: 0;
    padding-bottom: 0;
    border-radius: 0 !important;
    font-size: 88%;
}
.layer-list-item:hover .layer-actions {
    display: block;
}

/* Media */

.full-wrapper .review-image {
    max-width: 100%;
    height: auto;
}

.video-responsive {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
    width: 100%;
}
.video-responsive iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

/* Stakeholders */

.geolocate-map-wrapper {
    width: 100%;
    height: 600px;
    height: 60vh;
    min-height: 300px;
    background: #eee;
    position: relative;
}
.geolocate-map-wrapper:after {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font: normal normal normal 14px/1 FontAwesome;
    content: '\f05b';
    color: #fff;
    text-shadow: 0px 0px 9px rgba(8, 24, 31, 0.75);
    font-size: 1.3em;
}

/* Notifications */
#comm-frame {
    width: 1px;
    min-width: 100%;
    border: none;
    height: calc(100vh - 126px);
}

/* Engagements */
.engagement-history .list-group-item {
    padding-left: 50px;
    position: relative;
}
.engagement-type-icon {
    position: absolute;
    width: 60px;
    height: 60px;
    background: #fff;
    line-height: 52px;
    font-size: 26px;
    text-align: center;
    border-radius: 50%;
    border: 4px solid #777;
    top: -22px;
    left: -32px;
}
.success .engagement-type-icon {
    border-color: #5cb85c;
    color: #5cb85c;
}
.warning .engagement-type-icon {
    border-color: #ffaa00;
    color: #ffaa00;
}
.danger .engagement-type-icon {
    border-color: #ef5350;
    color: #ef5350;
}
.primary .engagement-type-icon {
    border-color: #3bafda;
    color: #3bafda;
}

/* Modals */

// stop background scrolling
body.modal-open {
    overflow: hidden;
}

.modal .form-group {
    border-top: 1px solid #e5e5e5;
    margin-top: 1.5em;
    padding-top: 1.5em;
    margin-bottom: 0;
}
.modal .form-group:first-child {
    margin-top: 0;
    padding-top: 0;
    border-top: 0 none;
}

/* Snippets: List */

.snippet-edit-list-container {
    list-style: none;
    margin: 0;
    padding: 0;
}
.snippet-edit-list-item {
    position: relative;
    border-radius: 4px;
    border: 1px solid #eee;
    margin-top: 5px;
}
.snippet-edit-list-item .drag-handle,
.snippet-edit-list-item .remove-handle {
    width: 30px;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background: #fafafa;
    color: #999;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}
.snippet-edit-list-item .remove-handle {
    left: auto;
    right: 0;
}
.snippet-edit-list-detail {
    padding: 15px 45px;
}
.snippet-edit-list-detail .form-group:last-child {
    margin-bottom: 0;
}
.snippet-edit-list-detail .control-label {
    font-size: 75%;
    text-transform: uppercase;
    font-weight: 300;
    display: block;
}
.snippet-edit-list-detail .radio-inline {
    padding-top: 6px;
    padding-bottom: 6px;
}

/* Engagements */

.list-group-item {
    background: #f6f9fa;
    margin: 0 0 20px;
    border: 0 none;
}

/* layered icons */
.ca-layers {
    display: inline-block;
    height: 1em;
    position: relative;
    text-align: center;
    vertical-align: -0.125em;
    width: 1em;
}

.ca-layers-counter {
    display: inline-block;
    position: absolute;
    text-align: center;
    background-color: #ff253a;
    border-radius: 1em;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: #fff;
    height: 1.5em;
    line-height: 1;
    max-width: 26px;
    min-width: 1.5em;
    overflow: hidden;
    padding: 0.25em;
    right: -12px;
    text-overflow: ellipsis;
    top: -6px;
    font-size: 90%;
}

/* Plugins: Dropzone */

.dz-message {
    padding: 90px 15px;
    text-align: center;
    color: #999;
}

/* Error page */
.error-icon {
    color: #ef5350;
    font-size: 76px;
    margin-bottom: 40px;
}

/* Notifications */
.ca-notification {
    .toasted-container & {
        &.toasted .action {
            font-size: 1.2rem;
        }
    }

    &.is-media i {
        margin-right: 1rem;

        img {
            max-width: 128px;
            max-height: 128px;
        }
    }
}

#print-modal-button {
    float: right;
    margin-right: 10px;
    margin-left: 10px;
    background: transparent;
    border: 0;
    padding: 0;
}

.btn.approve-selected,
.modal-approve-submission {
    background-color: #87c447;
    color: #ffffff;
}

.btn.reject-selected,
.modal-reject-submission {
    background-color: #ea233f;
    color: #ffffff;
}

.public-team-response {
    resize: vertical;
    margin-bottom: 10px;
}

.public-team-response-saved {
    margin-left: 10px;
}

.select-buttons {
    float: left;
    margin-right: 10px;
}

/*
 * Share media
 */
.relative {
    position: relative;
}
.align-bottom-right {
    position: absolute;
    right: 0;
    bottom: 0;
}

table.dataTable thead .sorting_asc:after {
    opacity: 1 !important;
}

table.dataTable thead .sorting_desc:after {
    opacity: 1 !important;
}

table.dataTable-click tr td:hover {
    cursor: pointer;
}

.btn.btn-approval {
    padding: 6px;
}

.btn.btn-approval .caret,
.btn.btn-replied .caret {
    margin-left: 1em;
}

table.dataTable thead .sorting_asc,
table.dataTable thead .sorting_desc {
    color: #346be2;
}
table.dataTable thead th.sorting:after,
table.dataTable thead th.sorting_asc:after,
table.dataTable thead th.sorting_desc:after {
    position: relative;
    top: auto;
    right: auto;
    margin-left: 0.55em;
    display: inline-block;
    vertical-align: bottom;
}

@media (max-width: $screen-lg-max) {
    #topnav .navbar-header {
        float: none;
        padding-right: 15px;
    }
    #topnav .navbar-toggle {
        width: 30px;
    }
    #topnav .topbar-main .collapse {
        display: none;
    }
    #topnav .topbar-main .collapse.in {
        display: block;
    }
    .navbar-default .navbar-nav .open .dropdown-menu > li > a {
        padding: 8px 12px;
    }

    .content-page {
        margin: 0;
    }

    .edit-suite {
        margin: 0 -15px;
        display: flex;
        flex-direction: column-reverse;
        height: calc(100vh - #{$header-height});
        min-height: auto;
    }
    .side-bar.edit-suite-sidebar {
        position: relative !important;
        width: 100%;
        height: auto;
        float: none;
        top: auto;
        left: auto;
    }
    .side-bar.edit-suite-sidebar-offscreen {
        right: -100vw;
        display: none;
    }
    .side-bar.edit-suite-sidebar-offscreen.active {
        right: 0;
        display: block;
    }
    .edit-suite .edit-suite-content {
        //height: 60vw;
        position: relative !important;
        top: auto;
        left: auto;
        width: 100%;
    }

    .side-bar.is-open + .edit-suite-content {
        width: calc(100% - #{$sidebar-width-mobile});
    }
}

@media (max-width: $screen-xs-max) {
    .side-bar.is-open + .edit-suite-content {
        display: none;
    }
}

@media (max-width: $screen-md-max) {
    .touch .hidden-ios {
        display: none !important;
    }

    #topnav .topbar-main .nav .dropdown-menu {
        position: relative;
    }
}

@media (min-width: $screen-lg-min) {
    #topnav .topbar-main .collapse {
        height: auto !important;
    }

    .breadcrumbs > .dropdown-toggle {
        display: none;
    }
    .breadcrumb-list {
        display: inline-block;
        position: relative;
        transition: unset;
        box-shadow: none;
    }
    .breadcrumb-list.dropdown-menu li.active:before {
        padding-right: 12px;
    }
    .breadcrumb-list.dropdown-menu li a {
        border-left: 0 none;
        color: $brand-primary;
        padding: 6px;
        display: inline;
    }
}

@media (max-width: $screen-lg-min) {
    .breadcrumbs {
        position: relative;
    }
    ol.breadcrumb {
        background: #fff;
    }
    .breadcrumb li {
        display: block;
        padding: 0;
    }
    .breadcrumb li:before {
        display: none;
    }
    .breadcrumb li.active,
    .dropdown-menu.breadcrumb-list li a {
        padding: 12px 16px;
        display: block;
    }
    .dropdown-menu.breadcrumb-list li a {
        color: $brand-primary;
    }
}

@media (min-aspect-ratio: 1/1) {
    .review-image-wrapper {
        max-width: 135vh;
        max-width: calc((100vh - 210px) * 1.777);
    }
}

@media screen {
    #printSection {
        display: none;
    }
}

@media print {
    /* override bootstrap sass print.scss */
    *,
    *:before,
    *:after {
        color: inherit !important;
    }
    a[href] {
        color: $brand-primary !important;

        &:after {
            content: none !important;
        }
    }
    /* end overrides */
    body.print-hide * {
        visibility: hidden;
        height: 0;
    }
    body.print-hide .print-show,
    body.print-hide .print-show * {
        visibility: visible;
        height: auto;
    }
    body.print-hide .print-show {
        position: absolute;
        left: 0;
        top: 0;
    }

    .dashboard-cards a {
        color: white !important;
    }
}
