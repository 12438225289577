.alert {
    &-overlay {
        border-radius: 0;
        position: fixed;

        left: 0;
        width: 100%;
        z-index: 9999;
        opacity: 0.8;
        margin-bottom: 0;
        padding: 1rem;
        text-align: center;
        font-weight: 600;
        font-size: 1em;
        -webkit-transition: opacity 0.2s ease-in-out;
        -moz-transition: opacity 0.2s ease-in-out;
        -ms-transition: opacity 0.2s ease-in-out;
        -o-transition: opacity 0.2s ease-in-out;
        transition: opacity 0.2s ease-in-out;
    }

    &-dismissible {
        .close {
            top: 1px;
            right: 5px;
            opacity: 1;

            &:active,
            &:hover {
                opacity: 0.4;
            }

            i {
                pointer-events: none;
            }
        }
    }

    &-bottom {
        bottom: 0;
        border-left: 0;
        border-right: 0;
        border-bottom: 0;
    }

    &-top {
        top: 0;
        border-left: 0;
        border-right: 0;
        border-top: 0;
    }

    &-info {
        background-color: $brand-info !important;
        border-color: darken($brand-info, 4%) !important;
    }

    &-warning {
        background-color: $brand-warning !important;
        border-color: darken($brand-warning, 4%) !important;
    }

    &-success {
        background-color: $brand-success !important;
        border-color: darken($brand-success, 4%) !important;
    }

    &-danger {
        background-color: $brand-danger !important;
        border-color: darken($brand-danger, 4%) !important;
    }

    &-info,
    &-warning,
    &-success,
    &-danger {
        color: white !important;

        .alert-link {
            color: white !important;
        }
    }
}
