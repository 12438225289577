.vue-treeselect {
    &--single {
        .vue-treeselect__option {
            &--selected {
                background: $brand-primary !important;
                color: white;
            }
        }
    }
}
