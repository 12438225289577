.side-bar.is-saving,
.reply.is-saving {
    position: relative;

    &:after {
        display: block;
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: rgba(white, 0.4);
    }
}
.side-bar.edit-suite-sidebar {
    right: 0;
    top: $header-height;
    position: fixed;
    height: 100%;
    height: calc(100% - #{$header-height});
    background: #fff;
    width: $sidebar-width;
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.19);
}
.side-bar.edit-suite-sidebar-offscreen {
    right: -#{$sidebar-width};
}
.side-bar.edit-suite-sidebar-offscreen.active {
    right: 0;
}
.side-bar.is-open + .edit-suite-content {
    position: fixed;
    width: calc(100% - #{$sidebar-width});
}
.edit-suite-sidebar .header-title {
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    font-size: 13px;
    padding-bottom: 5px;

    .btn-transparent {
        padding: 0 !important;
        text-transform: none;
        margin-left: 0.5rem;
        color: $text-color;

        &:focus,
        &:hover,
        &:active {
            background-color: transparent;
            border-color: transparent;
        }
    }
}
.edit-suite-sidebar .form-group {
    margin-bottom: 7px;
}
.edit-suite-sidebar .fancy-radio {
    font-size: 12px;
    height: 32px;
    line-height: 18px;
    width: 100%;
}
