/* ===========
   Panels
 =============*/
.panel {
    -moz-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
    border-radius: 0px;
    border: none;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
}
.panel .panel-body {
    padding: 20px;
}
.panel .panel-body p {
    margin: 0px;
}
.panel .panel-body p + p {
    margin-top: 15px;
}
.panel-heading {
    border-radius: 0;
    border: none !important;
    padding: 10px 20px;
}
.panel-default > .panel-heading {
    background-color: #fafafa;
    border-bottom: none;
    color: #797979;
}
.panel-title {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 0;
    margin-top: 0;
    text-transform: uppercase;
    letter-spacing: 0.03em;
}
.panel-sub-title {
    margin-bottom: 3px;
    color: #cee4fd;
    margin-top: -3px;
}
.panel-footer {
    background: #fafafa;
    border-top: 0px;
}
.panel-default .panel-sub-title {
    color: inherit;
}
.panel-color .panel-title {
    color: #ffffff;
}
.panel-primary > .panel-heading {
    background-color: #3bafda;
}
.panel-success > .panel-heading {
    background-color: #00b19d;
}
.panel-info > .panel-heading {
    background-color: #6cc6d6;
}
.panel-warning > .panel-heading {
    background-color: #ffaa00;
}
.panel-danger > .panel-heading {
    background-color: #ef5350;
}
.panel-purple > .panel-heading {
    background-color: #7266ba;
}
.panel-pink > .panel-heading {
    background-color: #f76397;
}
.panel-inverse > .panel-heading {
    background-color: #4c5667;
}
.panel-border {
    border-radius: 3px;
}
.panel-border .panel-heading {
    background-color: #ffffff;
    border-top: 3px solid #ccc !important;
    border-radius: 3px;
    padding: 10px 20px 0px;
}
.panel-border .panel-body {
    padding: 15px 20px 20px 20px;
}
.panel-border.panel-primary .panel-heading {
    border-color: #3bafda !important;
    color: #3bafda !important;
}
.panel-border.panel-success .panel-heading {
    border-color: #00b19d !important;
    color: #00b19d !important;
}
.panel-border.panel-info .panel-heading {
    border-color: #6cc6d6 !important;
    color: #6cc6d6 !important;
}
.panel-border.panel-warning .panel-heading {
    border-color: #ffaa00 !important;
    color: #ffaa00 !important;
}
.panel-border.panel-danger .panel-heading {
    border-color: #ef5350 !important;
    color: #ef5350 !important;
}
.panel-border.panel-purple .panel-heading {
    border-color: #7266ba !important;
    color: #7266ba !important;
}
.panel-border.panel-pink .panel-heading {
    border-color: #f76397 !important;
    color: #f76397 !important;
}
.panel-border.panel-inverse .panel-heading {
    border-color: #4c5667 !important;
    color: #4c5667 !important;
}
.panel-group .panel .panel-heading a[data-toggle='collapse'].collapsed:before {
    content: '\f067';
}
.panel-group .panel .panel-heading .accordion-toggle.collapsed:before {
    content: '\f067';
}
.panel-group .panel .panel-heading a[data-toggle='collapse'] {
    display: block;
}
.panel-group .panel .panel-heading a[data-toggle='collapse']:before {
    content: '\f068';
    display: block;
    float: right;
    font-family: 'Font Awesome 5 Pro';
    font-size: 14px;
    text-align: right;
    width: 25px;
}
.panel-group .panel .panel-heading .accordion-toggle {
    display: block;
}
.panel-group .panel .panel-heading .accordion-toggle:before {
    content: '\f068';
    display: block;
    float: right;
    font-family: 'Font Awesome 5 Pro';
    font-size: 14px;
    text-align: right;
    width: 25px;
}
.panel-group .panel .panel-heading + .panel-collapse .panel-body {
    border-top: none;
}
.panel-group .panel-heading {
    padding: 12px 26px;
}
.panel-group.panel-group-joined .panel + .panel {
    border-top: 1px solid #eeeeee;
    margin-top: 0;
}
.panel-group-joined .panel-group .panel + .panel {
    border-top: 1px solid #eeeeee;
    margin-top: 0;
}
