.wizard {
    &-steps {
        margin: 0;
        padding: 0;
        overflow: hidden;
    }

    &-steps li {
        list-style-type: none;
        display: inline-block;

        position: relative;
        margin: 0;
        padding: 0;

        text-align: center;
        line-height: 30px;
        height: 30px;

        background-color: #f0f0f0;

        &.active {
            background-color: $brand-primary;

            i {
                color: white;
            }
        }
    }

    &-steps[data-steps='3'] li {
        width: 33%;
    }
    &-steps[data-steps='4'] li {
        width: 25%;
    }
    &-steps[data-steps='5'] li {
        width: 20%;
    }

    &-steps li > a {
        display: block;
        color: $text-color;
        font-weight: 400;
        text-transform: uppercase;
    }

    &-steps li > a:after,
    &-steps li > a:before {
        content: '';
        display: block;
        width: 0px;
        height: 0px;

        position: absolute;
        top: 0;
        left: 0;

        border: solid transparent;
        border-left-color: #f0f0f0;
        border-width: 15px;
    }

    &-steps li.active + li > a:after,
    &-steps li.active + li > a:before {
        border-left-color: $brand-primary;
    }

    &-steps li > a:after {
        top: -5px;
        z-index: 1;
        border-left-color: white;
        border-width: 20px;
    }

    &-steps li > a:before {
        z-index: 2;
    }

    &-steps li:first-child > a:after,
    &-steps li:first-child > a:before {
        display: none;
    }

    &-icon {
        display: inline-block;

        &-container {
            display: inline-block;
        }
    }

    .stepTitle {
        &.active {
            color: white;
        }
    }

    &-tab-content {
        padding: 1rem;
        margin: 1rem 0;
    }

    &-footer {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;

        &-left {
        }

        &-right {
        }
    }
}
