/* Notifications */
.ca-notification {
    .toasted-container & {
        &.toasted .action {
            font-size: 1.2rem;
        }
    }

    &.is-media i {
        margin-right: 1rem;

        img {
            max-width: 128px;
            max-height: 128px;
        }
    }

    &.toasted.toasted-primary {
        &.success {
            background: map-get($ca-branding, 'success');
        }

        &.error {
            background: map-get($ca-branding, 'error');
        }

        &.warn {
            background: map-get($ca-branding, 'warning');
        }

        &.info {
            background: map-get($ca-branding, 'primary');
        }

        &.success,
        &.error,
        &.warn,
        &.info {
            .action {
                color: white;
            }
        }
    }
}
