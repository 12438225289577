/* ===========
   Calendar
 =============*/
.calendar {
    float: left;
    margin-bottom: 0px;
}
.none-border .modal-footer {
    border-top: none;
}
.fc-toolbar {
    margin-bottom: 5px;
}
.fc-toolbar h2 {
    font-size: 18px;
    font-weight: 600;
    line-height: 30px;
    text-transform: uppercase;
}
.fc-day {
    background: #ffffff;
}
.fc-toolbar .fc-state-active,
.fc-toolbar .ui-state-active,
.fc-toolbar button:focus,
.fc-toolbar button:hover,
.fc-toolbar .ui-state-hover {
    z-index: 0;
}
.fc-widget-header {
    border: 1px solid #d5d5d5;
}
.fc-widget-content {
    border: 1px solid #d5d5d5;
}
.fc th.fc-widget-header {
    background: #dddddd;
    font-size: 14px;
    line-height: 20px;
    padding: 10px 0px;
    text-transform: uppercase;
}
.fc-button {
    background: #ffffff;
    border: 1px solid #d5d5d5;
    color: #555555;
    text-transform: capitalize;
}
.fc-text-arrow {
    font-family: arial;
    font-size: 16px;
}
.fc-state-hover {
    background: #f5f5f5;
}
.fc-state-highlight {
    background: #f0f0f0;
}
.fc-cell-overlay {
    background: #f0f0f0;
}
.fc-unthemed .fc-today {
    background: #ffffff;
}
.fc-event {
    border-radius: 2px;
    border: none;
    cursor: move;
    font-size: 13px;
    margin: 5px 7px;
    padding: 5px 5px;
    text-align: center;
}
.external-event {
    color: #ffffff;
    cursor: move;
    margin: 10px 0;
    padding: 6px 10px;
}
.fc-basic-view td.fc-week-number span {
    padding-right: 5px;
}
.fc-basic-view td.fc-day-number {
    padding-right: 5px;
}
