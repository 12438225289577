/* ===========
   Progressbars
 =============*/
.progress {
    -webkit-box-shadow: none !important;
    background-color: #f5f5f5;
    box-shadow: none !important;
    height: 10px;
    margin-bottom: 18px;
    overflow: hidden;
}
.progress-bar {
    box-shadow: none;
    font-size: 8px;
    font-weight: 600;
    line-height: 12px;
}
.progress.progress-sm {
    height: 5px !important;
}
.progress.progress-sm .progress-bar {
    font-size: 8px;
    line-height: 5px;
}
.progress.progress-md {
    height: 15px !important;
}
.progress.progress-md .progress-bar {
    font-size: 10.8px;
    line-height: 14.4px;
}
.progress.progress-lg {
    height: 20px !important;
}
.progress.progress-lg .progress-bar {
    font-size: 12px;
    line-height: 20px;
}
.progress-bar-primary {
    background-color: #3bafda;
}
.progress-bar-success {
    background-color: #00b19d;
}
.progress-bar-info {
    background-color: #6cc6d6;
}
.progress-bar-warning {
    background-color: #ffaa00;
}
.progress-bar-danger {
    background-color: #ef5350;
}
.progress-bar-inverse {
    background-color: #4c5667;
}
.progress-bar-purple {
    background-color: #7266ba;
}
.progress-bar-pink {
    background-color: #f76397;
}
.progress-animated {
    -webkit-animation-duration: 5s;
    -webkit-animation-name: animationProgress;
    -webkit-transition: 5s all;
    animation-duration: 5s;
    animation-name: animationProgress;
    transition: 5s all;
}
